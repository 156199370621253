import React from 'react';
import Container from '../../../../components/container';
import { useParams } from 'react-router-dom';
import Text from '../../../../components/text';
import DriverVehicleVertification from '../../../../components/drivers/vehicle/vertification';
import styles from '../styles.module.scss'

type TypeVehicleVertificationPageProps = {
  children?: React.ReactNode;
};

const VehicleVertificationPage: React.FC<TypeVehicleVertificationPageProps> = (props) => {
  const { driverId, vehicleId } = useParams();
  return (
    <Container gap={20} className={styles.container}>
      <Text size={'xl'} weight='bold'>Verifikasi Kendaraan</Text>
      <DriverVehicleVertification driverId={driverId as string} vehicleId={vehicleId as string} />
    </Container>
  )
};

export default VehicleVertificationPage;