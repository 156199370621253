import { IStationAreaResourceShortProps } from '../../props/stations/area';
import Request from './../general/request';

const StationAreaService = {
    retrieve: async () => {
        try {
            const response = await Request.get<IStationAreaResourceShortProps[]>('/api/stations/areas');
            return response;
        } catch (error) {
            throw error
        }
    },
    read: async (areaId: number) => {
        try {
            const response = await Request.get<IStationAreaResourceShortProps>(`/api/stations/areas/${areaId}`);
            return response;
        } catch (error) {
            throw error
        }
    },
    create: async (data: FormData) => {
      try {
        const response = await Request.post<any>('/api/stations/areas', data);  
        return response;
      } catch (error) {
        throw error
      }
    },
    update: async (areaId: number, data: FormData) => {
      try {
        const response = await Request.put<any>(`/api/stations/areas/${areaId}`, data);  
        return response;
      } catch (error) {
        throw error
      }
    }
}

export default StationAreaService;