import { faArrowRight, faSearch, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Button, Checkbox, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure, Image } from '@nextui-org/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Container from '../../container';
import Text from '../../text';
import { IDriverResourceShort } from '../../../props/drivers';
import DriverService from '../../../services/drivers';
import GlobalConfig from '../../../config';
import { ICustomerResourceShort } from '../../../props/customers';
import CustomerService from '../../../services/customers';

const _historyTypes = ['topup', 'deduct']

type TypeFilterhistoryProps = {
  children?: React.ReactNode;
  onClose: () => void
  onSave: (data: {
    date: {
      startDate?: string,
      endDate?: string,
    },
    historyTypes: string[],
    driver?: ICustomerResourceShort
  }) => void

  date?: {
    startDate?: string,
    endDate?: string,
  }
  historyTypes?: string[]
  driver?: ICustomerResourceShort

};

const FilterhistorySales: React.FC<TypeFilterhistoryProps> = (props) => {
  const [startDate, setStartDate] = React.useState<string>(props.date?.startDate || moment().startOf('day').toISOString());
  const [endDate, setEndDate] = React.useState<string>(props.date?.endDate || moment().endOf('day').toISOString());
  const [historyTypes, setHistoryTypes] = useState<string[]>(props.historyTypes || [])
  const [driver, setDriver] = useState<ICustomerResourceShort | undefined>(props?.driver || undefined)
  const [search, setSearch] = React.useState<string>();
  const [drivers, setDrivers] = React.useState<ICustomerResourceShort[]>([]);

  useEffect(() => {
    search !== undefined && getDrivers()
    return () => { }
  }, [search])

  const getDrivers = async () => {
    const qs: string[] = [];
    qs.push('limit', '3')
    if (search && search.trim() !== '') { qs.push("search=" + search); }
    try {
      const items = await CustomerService.retrieve(qs.join('&'))
      setDrivers(items)
    } catch (error) {

    }
  }

  return (
    <Modal closeButton={false} hideCloseButton isOpen={true} onOpenChange={(isOpen: boolean) => { }}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">Filter</ModalHeader>
        <ModalBody>
          <Container gap={20}>
            <Container direction='row' gap={10} style={{ zIndex: 1 }} alignItems='center'>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Container grow={1}>
                  <DatePicker label={'Mulai Tanggal'} format='DD/MM/YYYY' value={moment(startDate)} onChange={(val) => {
                    const _startDate = val ? val.startOf('day').toISOString() : startDate;
                    setStartDate(_startDate);
                  }} />
                </Container>
                <FontAwesomeIcon icon={faArrowRight} />
                <Container grow={1}>
                  <DatePicker format='DD/MM/YYYY' label={'Sampai Tanggal'} value={moment(endDate)} onChange={(val) => {
                    const _endDate = val ? val.endOf('day').toISOString() : endDate;
                    setEndDate(_endDate);
                  }} />
                </Container>
              </LocalizationProvider>
            </Container>

            <Container gap={10}>
              <Text>History Type</Text>
              {_historyTypes.map(type => <Checkbox
                defaultSelected={historyTypes.find(f => f == type) !== undefined}
                onValueChange={(isSelected: boolean) => {
                  let items = [...historyTypes]
                  if (isSelected) {
                    items.push(type)
                  } else {
                    items = items.filter(f => f !== type)
                  }
                  setHistoryTypes(items)
                }}
                key={type}>{type}</Checkbox>)}
            </Container>

            <Container gap={10}>
              <Text>Customer</Text>
              {driver && (
                <Container key={driver.id} direction='row' justifyContent='space-between' alignItems='center'>
                  <Container direction='row' alignItems='center' >
                    <Image
                      width={50}
                      height={50}
                      alt="profile_picture"
                      src={GlobalConfig.assetHostname + '/storage/' + driver.profile_picture}
                    />
                    <Text>{driver.first_name}</Text>
                  </Container>
                  <Button onClick={() => setDriver(undefined)} isIconOnly color='default' aria-label="Like">
                    <FontAwesomeIcon icon={faXmark} />
                  </Button>
                </Container>
              )}
              {!driver && (
                <>
                  <Input
                    placeholder="Cari customer ..."
                    endContent={
                      <FontAwesomeIcon icon={faSearch} />
                    }
                    style={{ zIndex: 1 }}
                    value={search}
                    onValueChange={(value) => {
                      setSearch(value);
                    }}
                  />
                  {drivers.map(d => (
                    <Container style={{ cursor: 'pointer' }} key={d.id} direction='row' alignItems='center' onClick={() => setDriver(d)}>
                      <Image
                        width={50}
                        height={50}
                        alt="profile_picture"
                        src={GlobalConfig.assetHostname + '/storage/' + d.profile_picture}
                      />
                      <Text>{d.first_name}</Text>
                    </Container>
                  ))}
                </>
              )}
            </Container>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color='default' onPress={() => {
            props.onClose()
          }}>
            Batal
          </Button>
          <Button color="primary" onPress={() => {
            props.onSave({
              date: { startDate, endDate },
              historyTypes,
              driver
            })
          }}>
            Simpan
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
};

export default FilterhistorySales;