import React from 'react';
import Container from '../../../components/container';
import Text from '../../../components/text';
import styles from './styles.module.scss'
import { Button, Input } from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/pro-light-svg-icons';
import ListCustomer from '../../../components/customers/list';

type TypeCustomersPageProps = {
  children?: React.ReactNode;
};

const CustomersPage: React.FC<TypeCustomersPageProps> = (props) => {
  return (
    <Container className={styles.container} gap={20}>
      <Text size={'xl'} weight='bold'>Daftar Pelanggan</Text>
      {/* <Container direction='row' gap={10} style={{ zIndex: 1 }}>
        <Input
          placeholder="Cari pelanggan ..."
          labelPlacement="outside"
          size='sm'
          endContent={
            <FontAwesomeIcon icon={faSearch} size='xs' />
          }
          style={{ zIndex: 1 }}
        />
      </Container> */}
      <ListCustomer />
    </Container>
  )
};

export default CustomersPage;