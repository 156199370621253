import React, { useEffect } from 'react';
import Text from '../../text';
import { faChevronDown, faSearch, faTrash, faXmark } from '@fortawesome/pro-light-svg-icons';
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Input, Button, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Spinner, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Textarea } from "@nextui-org/react";
import DropdownButton, { DropdownButtonSectionProps } from '../../buttons/dropdown';
import Container from '../../container';
import styles from './styles.module.scss';
import Footer from '../../footer';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import StationAreaService from '../../../services/station/area';
import { IVehicleCategoryResourceShortProps } from '../../../props/vehicles/type';
import VehicleCategoryService from '../../../services/vehicle/category';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import GlobalConfig from '../../../config';


type InputPickerProps = {
    selected?: PickerItemProps | PickerItemProps[];
    label?: string;
    noItemsText?: string;
    onSearch?(keyword: string): Promise<PickerItemProps[]>;
    onSelect?(item: PickerItemProps): void;
    onDelete?(item: PickerItemProps): void;
};

export type PickerItemProps = {
    key: string;
    title: string;
    description?: string;
    data?: any;
}

const InputPicker: React.FC<InputPickerProps> = (props) => {
    const [keyword, setKeyword] = React.useState<string>("");
    const [searching, setSearching] = React.useState<boolean>(false);
    const [suggestions, setSuggestions] = React.useState<PickerItemProps[]>([]);
    const [showSuggestions, setShowSuggestions] = React.useState<boolean>(false);
    const [selected, setSelected] = React.useState<PickerItemProps[]>([]);

    useEffect(() => {
        if (Array.isArray(props.selected)) {
            setSelected(props.selected);
        } else if (props.selected) {
            setSelected([props.selected]);
        } else {
            setSelected([]);
        }
    }, [props.selected]);

    const _onSearch = async (value: string) => {
        setKeyword(value);
        setSearching(true);
        setSuggestions([]);
        setShowSuggestions(true);

        if (props.onSearch) {
            const _suggestions = await props.onSearch(value);
            setSuggestions(_suggestions);
            setSearching(false);
        }

        setSearching(false);
    }

    const _onDelete = (item: PickerItemProps) => {
        if (props.onDelete) {
            props.onDelete(item);
        }
    }

    const _onSelect = (item: PickerItemProps) => {

        if (props.onSelect) {
            props.onSelect(item);
            setShowSuggestions(false);
        }

        setKeyword("");
        setShowSuggestions(false);
    }

    return <Container gap={3}>
        {props.label ? <Text size={'small'} weight={'semibold'}>{props.label}</Text> : null}
        <Container className={styles.inputContainer}>
            <Container wrap={true} direction='row' className={styles.input} gap={5}>
                {selected.map((sel) => {
                    return <Container className={styles.selectedItem}>
                        <Container className={styles.content} alignItems='center' direction='row'>
                            <Container className={styles.body}>
                                <Text size={'small'}>{sel.title}</Text>
                            </Container>
                            <a className={styles.delete} onClick={() => _onDelete(sel)}><FontAwesomeIcon icon={faXmark} fontSize={12} /></a>
                        </Container>
                    </Container>;
                })}
                <Container grow={1}>
                    <Input fullWidth={true} variant='flat' style={{ width: 'auto', flexGrow: 1, minWidth: 200, background: 'transparant', boxShadow: 'none', border: 0, outline: 0 }} onValueChange={_onSearch} onFocus={() => _onSearch(keyword)} />
                </Container>
            </Container>
            {showSuggestions ? <>
                <Container className={styles.suggestionsBox}>
                    {searching ? <div style={{ textAlign: 'center', padding: 10 }}><Spinner size={'sm'} /></div> : null}
                    {!searching && suggestions.length < 1 ? <div style={{ padding: 20, textAlign: 'center' }}><Text size={'small'}>{props.noItemsText || 'Data tidak ditemukan'}</Text></div> : null}
                    {!searching && suggestions.length > 0 ? suggestions.map((sug) => {
                        return <Container onClick={() => _onSelect(sug)} key={sug.key} className={styles.suggestionItem}>
                            <Text size={'small'} weight='semibold'>{sug.title}</Text>
                            {sug.description ? <Text size='tiny'>{sug.description || ""}</Text> : null}
                        </Container>
                    }) : null}
                </Container>
                <Container className={styles.backdrop} onClick={() => setShowSuggestions(false)}> </Container>
            </> : null}
        </Container>
    </Container>
};

export default InputPicker;