import React from 'react';
import Container from '../../../components/container';
import Text from '../../../components/text';
import styles from './styles.module.scss'
import CustomerDetail from '../../../components/customers/details';
import { useParams } from 'react-router-dom';


type TypeDetailCustomerPageProps = {
  children?: React.ReactNode;
};

const DetailCustomerPage: React.FC<TypeDetailCustomerPageProps> = (props) => {
  const { id } = useParams();
  return (
    <Container className={styles.container} gap={20}>
      <CustomerDetail customerId={id as string} /> 
    </Container>
  )
};

export default DetailCustomerPage;