
import React from 'react';
import Container from '../../../components/container';
import Text from '../../../components/text';
import styles from './styles.module.scss'
import { Button, Input } from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/pro-light-svg-icons';
import ListCustomer from '../../../components/customers/absensi';

type TypeCustomersPageProps = {
  children?: React.ReactNode;
};

const CustomersPage: React.FC<TypeCustomersPageProps> = (props) => {
  return (
    <Container className={styles.container} gap={20}>
      <ListCustomer />
    </Container>
  )
};

export default CustomersPage;