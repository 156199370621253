import React from 'react';
import Container from '../../../components/container';
import Text from '../../../components/text';
import styles from './styles.module.scss'
import ListDriver from '../../../components/drivers/list';

type DriverPageProps = {
  children?: React.ReactNode;
};

const DriverPage: React.FC<DriverPageProps> = (props) => {
  return (
    <Container className={styles.container} gap={20}>
      <Text size={'xl'} weight='bold'>Daftar Driver</Text>
      <ListDriver />
    </Container>
  )
};

export default DriverPage;