const NumberService = {
    getNumberWithSeparator: (number: string | number, separator?:string) => {
        number = number + '';
        if (parseFloat(number || "0") >= 1) {
            number = (number || "0");//.split(".").join("");
            number = number.replaceAll(".", ",");
            const decimal = number.split(",")[1];
            return number.split(",")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (decimal ? "," + decimal : "");
        } else if (parseFloat(number || "0") >= 0 && parseFloat(number || "0") < 1) {
            number = (number || "0");//.split(".").join("");
            number = number.replaceAll(".", ",");
            return number;
        } else if (parseFloat(number || "0") < 0) {
            number = (parseFloat(number || "0") * -1) + "";
            number = number.replaceAll(".", ",");
            const decimal = number.split(",")[1];
            return "-" + number.split(",")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (decimal ? "," + decimal : "");
        } else {
            return number;
        }
    }
};

export default NumberService;