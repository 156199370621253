import React, { useEffect } from 'react';
import Container from '../container';
import Text from '../text';
import styles from './styles.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faGameConsoleHandheld, faSearch } from '@fortawesome/pro-light-svg-icons';
import { Chip, Input,Button, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownSection, DropdownTrigger, Modal, ModalBody, ModalContent, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react';
import { ICustomerResourceShort } from '../../props/customers';
import { ICustomerResourceShortExport } from '../../props/customers/list_export';
import { ICustomerResourceShortAbsensi } from '../../props/customers/absensi';
import CustomerService from '../../services/customers';
import DropdownButton, { DropdownButtonSectionProps } from '../buttons/dropdown';
import { NavLink, useNavigate } from 'react-router-dom';

import * as XLSX from 'xlsx';

import { ExportAsExcel, ExportAsPdf, CopyToClipboard, CopyTextToClipboard, PrintDocument, ExcelToJsonConverter, FileUpload } from "react-export-table";
import NumberService from '../../services/general/number';


type TypeListCustomerProps = {
  children?: React.ReactNode;
};

const ListAbsensi: React.FC<TypeListCustomerProps> = (props) => {
  const navigate = useNavigate();

  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [customers, setCustomers] = React.useState<ICustomerResourceShort[]>([]);
  const [customersExport, setCustomersExport] = React.useState<ICustomerResourceShortAbsensi[]>([]);
  const [updating, setUpdating] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>();

  const [selectedImage, setSelectedImage] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
 
  let tableData = [
    ['', '', '']
  ];

  const handleImageClick = (imageUrl:any) => {
    setSelectedImage(imageUrl);  // Set the clicked image URL to the state
    setIsModalOpen(true);        // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);       // Close the modal
    setSelectedImage(null);      // Clear the selected image
  };

  const formatDate = (date:any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getToday = () => formatDate(new Date());

  const getYesterday = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return formatDate(yesterday);
  };

  const getThreeDaysAgo = () => {
    const threeDaysAgo = new Date();
    threeDaysAgo.setDate(threeDaysAgo.getDate() - 2);
    return formatDate(threeDaysAgo);
  };
  
  const getAllDataAbsen = () => {
    return 'alldata';
  };

  useEffect(() => {
    init(getToday());
    return () => { }
  }, [search]);

  // const init = async () => {
  //   const _customers = (await CustomerService.retrieve());
  //   setCustomers(_customers);
  //   setLoaded(true);
  // }

  const init = async (searchTerm:any) => {
    setLoaded(false);
    const qs = [];
    if (searchTerm && searchTerm.trim() !== '') {
      qs.push('search=' + searchTerm);
    }
    try {
      const items = await CustomerService.retrieve_absensi(qs.join(';'));
      setCustomersExport([]);
      setLoaded(true);
      setCustomersExport(items);
    } catch (error) {
      console.error(error);
    }
  };

  const jsonResult = customersExport.map((item, index) => {
    return {
      No: index+1,
      Nama: "",
      'Time': item.waktu_absen,
      'Shift': item.id_shift,
      'Kesimpulan' : item.kesimpulan,
    };
  });

  const exportToExcel = (jsonResult: any[]) => {
    // Convert data to worksheet
    const ws = XLSX.utils.json_to_sheet(jsonResult);
  
    // Auto-width columns
    const colWidth = jsonResult.reduce((acc, data) => {
      return Object.keys(data).reduce((acc, key) => {
        const cellLength = String(data[key]).length;
        return { ...acc, [key]: Math.max(acc[key] || 0, cellLength) };
      }, acc);
    }, {});
  
    const cols = Object.keys(colWidth).map(key => ({ wch: colWidth[key] + 2 })); // Add extra space
  
    ws['!cols'] = cols;
  
    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    // Save the workbook to a file
    XLSX.writeFile(wb, 'Export Data Sales.xlsx');
  };
	const actions: DropdownButtonSectionProps[] = [
		{
			key: 'details',
			showDivider: false,
			items: [
				{ key: 'setConfirmation', text: 'Konfirmasi', onPress: (customer: ICustomerResourceShortAbsensi) => {
            setUpdating(true);
            setConfirmation(customer);
          }
        },
        { key: 'view', text: 'Lihat detail', onPress: (customer: ICustomerResourceShort) => { navigate(`/customers/details/${customer.id}`); } },
			]
		}
	];
	
  const actions2: DropdownButtonSectionProps[] = [
		{
			key: 'details',
			showDivider: false,
			items: [
				{ key: 'view', text: 'Export Data', onPress: (customer: ICustomerResourceShort) => { navigate(`/customers/details/${customer.id}`); } },
			]
		}
	];

  const setConfirmation = async (customer: ICustomerResourceShortAbsensi) => {
    const fd = new FormData
    fd.append('absen_id', customer.id + "");

    await CustomerService.setConfirmAbsen((customer.id_actor), fd)
    setUpdating(false);
    setLoaded(false);
    init(getToday());
    alert('Absen berhasil diterima.');
    
  }
  
  const handleClick = () => {
    console.log(jsonResult);
    exportToExcel(jsonResult);
  };
  

  return (
    
    <Container gap={20} className={styles.container}>
      <Container>
      <h1 className={styles.h1}>Absensi</h1>
      </Container>
      <Container direction='row' gap={10} style={{ zIndex: 1 }}>
      <button className={styles.btn} onClick={() => init(getToday())}>Hari Ini</button>
      <button className={styles.btn} onClick={() => init(getYesterday())}>Kemarin</button>
      <button className={styles.btn} onClick={() => init(getThreeDaysAgo())}>2 hari lalu</button>
      <button className={styles.btn} onClick={() => init(getAllDataAbsen())}>Tampilkan Semua = Status : Waiting</button>
		</Container>
      <Table  aria-label="Daftar area" isStriped>
        <TableHeader>
          <TableColumn>No</TableColumn>
          <TableColumn>Nama</TableColumn>
          <TableColumn>Time</TableColumn>
          <TableColumn>Shift</TableColumn>
          <TableColumn>Kesimpulan</TableColumn>
          <TableColumn>Status</TableColumn>
          <TableColumn>Aksi</TableColumn>
          
        </TableHeader>
        <TableBody isLoading={!loaded} loadingContent={<Spinner />} emptyContent={<Text>Belum ada data.</Text>}>
          {customersExport.map((customer, index) => {
            return <TableRow key={customer.id}>
              <TableCell>
                <Text size='base' weight='medium'>
                  {index+1}
                </Text>
              </TableCell>
              <TableCell style={{
                display:'flex',
                alignItems:'center',
                gap:'10px'
              }}>
              <img
                src={"https://absen.mydrivers.cloud/"+customer.foto}
                alt="Customer"
                style={{
                  width: '40px', // Adjust the width of the image
                  height: '40px', // Adjust the height of the image
                  borderRadius: '50%', // Make the image circular
                  objectFit: 'cover',
                  display:'flex' // Ensure the image covers the entire area
                }}
                onClick={() => handleImageClick("https://absen.mydrivers.cloud/"+customer.foto)} // Trigger the modal on click

              />
                <Text size='base' weight='medium'>
                  {customer.customer?.first_name} {customer.customer?.last_name||""}
                </Text>
              </TableCell>
              <TableCell>
                <Text size='base' weight='medium'>
                  {customer.waktu_absen} 
                </Text>
              </TableCell>
              <TableCell>
                <Text size='base' weight='medium'>
                {customer.shift?.keterangan||""}
                </Text>
              </TableCell>
              <TableCell>
                <Text size='base' weight='medium'>
                  {customer.kesimpulan}
                </Text>
              </TableCell>
              <TableCell>
                <Text size='base' weight='medium'>
                  {(customer.status=='aktif')?<Text style={{color:'red'}}>WAITING</Text>:<Text style={{color:'green'}}>CONFIRMED</Text>}
                </Text>
              </TableCell>
              <TableCell key={'action'}>
              {(customer.status=='aktif')?<DropdownButton icon={faChevronDown} sections={actions} data={customer} />:''}
                
              </TableCell>
              
            </TableRow>;
          })}
        </TableBody>
      </Table>

      {/* Modal */}
      {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              padding: '3px',
              borderRadius: '8px',
              position: 'relative'
            }}
          >
            <span
              onClick={handleCloseModal}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                color:'white',
                cursor: 'pointer',
                fontSize: '20px',
                fontWeight: 'bold',
                background:'red',
                padding:'7px',
                paddingTop:'5px',
                paddingBottom:'8px',
                lineHeight:'15px',
                borderRadius:'100%'
              }}
            >
              &times; {/* Close button */}
            </span>
            <img src={selectedImage||''}
              alt="Selected"
              style={{
                maxWidth: '100%',
                maxHeight: '80vh'
              }}
            />
          </div>
        </div>
      )}
      <Modal isOpen={updating}>
        <ModalContent>
          <ModalBody>
            <Spinner>Harap tunggu ...</Spinner>
          </ModalBody>
        </ModalContent>
      </Modal>

      
    </Container>
  );
};

export default ListAbsensi;