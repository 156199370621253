import React from 'react';
import Text from '../../../components/text';
import styles from './styles.module.scss'
import Container from '../../../components/container';
import ListOrder from '../../../components/orders/list';

type TypeOrderPageProps = {
  children?: React.ReactNode;
};

const OrderPage: React.FC<TypeOrderPageProps> = (props) => {
  return (
    <Container className={styles.container} gap={20}>
      <Text size={'xl'} weight='bold'>Daftar Pesanan</Text>
      <ListOrder />
    </Container>
  );
};

export default OrderPage;