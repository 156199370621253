import React from 'react';
import Container from '../../../components/container';
import Text from '../../../components/text';
import styles from './styles.module.scss'
import VertificationDriver from '../../../components/drivers/vertification';
import { useParams } from 'react-router-dom';

type VerificationDriverPageProps = {
  children?: React.ReactNode;
};

const VerificationDriverPage: React.FC<VerificationDriverPageProps> = (props) => {
  const { driverId } = useParams();
  return (
    <Container className={styles.container} gap={20}>
      <Text size={'xl'} weight='bold'>Verifikasi Driver</Text>
      <VertificationDriver driverId={driverId as string} />
    </Container>
  )
};

export default VerificationDriverPage;