import React from 'react';
import AreaForm from '../../../components/areas/form';
import { Routes, Route, useParams } from 'react-router-dom';
import Container from '../../../components/container';
import Text from '../../../components/text';
import styles from './styles.module.scss';

type UpdateAreaPageProps = {
    children?: React.ReactNode;
};

const UpdateAreaPage: React.FC<UpdateAreaPageProps> = (props) => {
    const { areaId } = useParams();
    return <Container className={styles.container} gap={20}>
      <Text size={'xl'} weight='bold'>Ubah Detail Area</Text>
      <AreaForm areaId={Number(areaId)} />
    </Container>;
};

export default UpdateAreaPage;