import React, { PropsWithChildren } from 'react';
import Text from '../../text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { Button, ButtonProps, Dropdown, DropdownItem, DropdownMenu, DropdownSection, DropdownTrigger } from '@nextui-org/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type DropdownButtonProps = PropsWithChildren<{
    key?: string;
    text?: string;
    icon?: IconProp;
    label?: string;
    variant?: ButtonProps["variant"];
    sections: DropdownButtonSectionProps[];
    data?: any;
    backdrop?: "blur" | "transparent" | "opaque" | undefined;
}>;

export type DropdownButtonSectionProps = {
    key: string;
    text?: string;
    showDivider?: boolean;
    items: DropdownButtonItemProps[];
}

export type DropdownButtonItemProps = {
    key: string;
    text: string;
    variant?: 'danger' | 'success';
    hide?: boolean;
    onPress(data: any): void;
}

const DropdownButton: React.FC<DropdownButtonProps> = (props) => {
    return <Dropdown key={props.key} backdrop={'transparent'} trigger={'press'}>
        <DropdownTrigger >
            <Button aria-label={props.text || props.key} variant={props.variant || 'light'} isIconOnly={props.icon && !props.text && !props.children}>
                {props.children ? props.children : null}
                {!props.children && props.text ? <Text>{props.text}</Text> : null}
                {!props.children && props.icon ? <FontAwesomeIcon icon={faChevronDown} size='lg' /> : null}
            </Button>
        </DropdownTrigger>
        <DropdownMenu aria-label={props.label || props.text || props.key || "button dropdown"}>
            {props.sections.map((section) => {
                return <DropdownSection key={section.key} showDivider={section.showDivider} aria-label={section.key}>
                    {section.items.filter((item) => !item.hide).map((item) => {
                        let color = item.variant;
                        let className = item.variant ? 'text-' + item.variant : undefined;

                        return <DropdownItem aria-label={item.text} aria-labelledby={item.text} onPress={() => item.onPress(props.data)} key={item.key} color={color} className={className}>{item.text}</DropdownItem>;
                    })}
                </DropdownSection>
            })}
        </DropdownMenu>
    </Dropdown>;
};

export default DropdownButton;