import React, { useEffect, useState } from 'react';
import Container from '../container';
import Text from '../text';
import styles from './styles.module.scss'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { Modal, ModalBody, ModalContent, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react';
import AdminService from '../../services/admins';
import DropdownButton, { DropdownButtonSectionProps } from '../buttons/dropdown';
import { IUserResourceShort } from '../../props/admin/customer';
import { useNavigate } from "react-router-dom";


type TypeListAdminProps = {
  children?: React.ReactNode;
  search?: string
};

const ListAdmin: React.FC<TypeListAdminProps> = (props) => {
  const navigate = useNavigate();

  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [admins, setAdmins] = React.useState<IUserResourceShort[]>([]);
  const [updating, setUpdating] = React.useState<boolean>(false);
  const [search, setSearch] = useState<string | undefined>(props.search)
  const [page, setPage] = useState<number>(1)


  useEffect(() => {
    setSearch(props.search)
    return () => { }
  }, [props.search])

  useEffect(() => {
    init();
    return () => { }
  }, [search]);

  const init = async () => {
    let qs: string[] = [];
    if (page) { qs.push("page=" + page); }
    if (search) { qs.push("search=" + search); }
    try {
      const _admins = (await AdminService.retrieve(qs.join('&')));
      setAdmins(_admins);
      setLoaded(true);
    } catch (error) {

    }
  }

  const actions: DropdownButtonSectionProps[] = [
    {
      key: 'update',
      showDivider: false,
      items: [
        {
          key: 'utd',
          text: 'Ubah',
          onPress: (customer: IUserResourceShort) => {
            navigate(`/admins/${customer.id}/update`)
          },
        },
        {
          key: 'changapassword',
          text: 'Ubah Password',
          onPress: (customer: IUserResourceShort) => {
            navigate(`/admins/${customer.id}/update-password`)
          },
        },
      ]
    }
  ];

  return (
    <Container className={styles.container}>
      <Table aria-label="Daftar area" isStriped>
        <TableHeader>
          <TableColumn>No</TableColumn>
          <TableColumn>NAMA</TableColumn>
          <TableColumn width={40}> </TableColumn>
        </TableHeader>
        <TableBody isLoading={!loaded} loadingContent={<Spinner />} emptyContent={<Text>Belum ada admin terdaftar</Text>}>
          {admins.map((admin, index) => {
            return <TableRow key={admin.id}>
              <TableCell>
                <Text size='base' weight='medium'>
                  {index+1}
                </Text>
              </TableCell>
              <TableCell>
                <Text size='base' weight='medium'>
                  {admin.name}
                </Text>
              </TableCell>
              <TableCell key={'action'}>
                {<DropdownButton icon={faChevronDown} sections={actions} data={admin} />}
              </TableCell>
            </TableRow>;
          })}
        </TableBody>
      </Table>
      <Modal isOpen={updating}>
        <ModalContent>
          <ModalBody>
            <Spinner>Harap tunggu ...</Spinner>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default ListAdmin;