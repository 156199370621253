import { IStationAreaPriceResourceShortProps } from '../../props/stations/price';
import Request from './../general/request';

const StationAreaPriceService = {
    retrieve: async (areaId: number) => {
        try {
            const response = await Request.get<IStationAreaPriceResourceShortProps[]>(`/api/stations/areas/${areaId}/prices`);
            return response;
        } catch (error) {
            throw error
        }
    },
    read: async (areaId: number, priceId: number) => {
        try {
            const response = await Request.get<IStationAreaPriceResourceShortProps>(`/api/stations/areas/${areaId}/prices/${priceId}`);
            return response;
        } catch (error) {
            throw error
        }
    },
    create: async (areaId: number, data: FormData) => {
      try {
        const response = await Request.post<any>(`/api/stations/areas/${areaId}/prices`, data);  
        return response;
      } catch (error) {
        throw error
      }
    },
    update: async (areaId: number, priceId: number, data: FormData) => {
      try {
        const response = await Request.put<any>(`/api/stations/areas/${areaId}/prices/${priceId}`, data);  
        return response;
      } catch (error) {
        throw error
      }
    },
    delete: async (areaId: number, priceId: number) => {
      try {
        const response = await Request.delete<any>(`/api/stations/areas/${areaId}/prices/${priceId}`);  
        return response;
      } catch (error) {
        throw error
      }
    }
}

export default StationAreaPriceService;