import { IUserResourceShort } from '../../props/admin/customer';
import Request from './../general/request';

const UserService = {
    me: async () => {
        try {
            const response = await Request.get<IUserResourceShort>('/api/admin/me');
            return response;
        } catch (error) {
            throw error
        }
    }
}

export default UserService;