import React, { useEffect } from 'react';
import Text from '../../text';
import { faChevronDown, faPlus, faSearch } from '@fortawesome/pro-light-svg-icons';
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Spinner, Input, Button } from "@nextui-org/react";
import DropdownButton, { DropdownButtonSectionProps } from '../../buttons/dropdown';
import { IStationAreaResourceShortProps } from '../../../props/stations/area';
import StationAreaService from '../../../services/station/area';
import Container from '../../container';
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InactivateArea from '../modals/inactivate';
import AreaQueueList from '../modals/queues';


type TypeListAreaProps = {
	children?: React.ReactNode;
};

const ListArea: React.FC<TypeListAreaProps> = (props) => {
	const navigate = useNavigate();
	
	const [areas, setAreas] = React.useState<IStationAreaResourceShortProps[]>([]);
	const [loaded, setLoaded] = React.useState<boolean>(false);
	const [search, setSearch] = React.useState<string>("");  
	const [activeArea, setActiveArea] = React.useState<IStationAreaResourceShortProps | undefined>();
	const [activeModal, setActiveModal] = React.useState<string>("");

	const actions: DropdownButtonSectionProps[] = [
		{
			key: 'details',
			showDivider: true,
			items: [
				{ key: 'view', text: 'Lihat detail', onPress: (area: IStationAreaResourceShortProps) => {navigate(`/areas/${area.id}/details`);} },
				{ key: 'update', text: 'Ubah detail', onPress: (area: IStationAreaResourceShortProps) => {navigate(`/areas/${area.id}/update`);} }
			]
		},
		{
			key: 'queues',
			showDivider: true,
			items: [
				{ key: 'queue', text: 'Lihat antrian', onPress: (area: IStationAreaResourceShortProps) => {
					setActiveArea(area);
					setActiveModal("queues");
				} }
			]
		},
		{
			key: 'deletion',
			items: [
				{ key: 'queue', text: 'Non-aktifkan area', variant: 'danger', onPress: (area: IStationAreaResourceShortProps) => {
					setActiveArea(area);
					setActiveModal("inactivate");
				} }
			]
		}
	];

	useEffect(() => {
		init();
	}, []);

	const init = async () => {
		const _areas = await StationAreaService.retrieve();
		setAreas(_areas.result);
		setLoaded(true);
	}

	const _onCloseModal = (refresh?: boolean) => {
		setActiveModal("");
		setActiveArea(undefined);

		if (refresh) {
			setLoaded(false);
			init();
		}
	}

	return <Container gap={20}>
		<Container direction='row' gap={10} style={{ zIndex: 1 }}>
			<Input
				placeholder="Cari area ..."
				endContent={
					<FontAwesomeIcon icon={faSearch} />
				}
				style={{ zIndex: 1 }}
				value={search}
				onValueChange={(value) => {
					setSearch(value);
				}}
			/>
			<NavLink to={"/areas/create"}>
				<Button isIconOnly color='primary'>
					<FontAwesomeIcon icon={faPlus} />
				</Button>
			</NavLink>
		</Container>
		<Table aria-label="Daftar area" isStriped>
			<TableHeader>
				<TableColumn width={40}>No</TableColumn>
				<TableColumn>NAMA</TableColumn>
				<TableColumn width={40}> </TableColumn>
			</TableHeader>
			<TableBody isLoading={!loaded} loadingContent={<Spinner />} emptyContent={<Text>Belum ada area terdaftar</Text>}>
				{areas.filter((area) => area.name.toLowerCase().indexOf(search.toLowerCase()) > -1).map((area, index) => {
					return <TableRow key={area.id}>
						<TableCell>
							<Text size='base' weight='medium'>{index+1}</Text>
						</TableCell>
						<TableCell>
							<Text size='base' weight='medium'>{area.name}</Text>
						</TableCell>
						<TableCell key={'action'}>
							<DropdownButton icon={faChevronDown} sections={actions} backdrop='blur' data={area} />
						</TableCell>
					</TableRow>;
				})}
			</TableBody>
		</Table>
		{activeModal === 'inactivate' && activeArea ? <InactivateArea area={activeArea} onClose={_onCloseModal} /> : null}
		{activeModal === 'queues' && activeArea ? <AreaQueueList area={activeArea} onClose={_onCloseModal} /> : null}
	</Container>;
};

export default ListArea;