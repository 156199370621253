import React, { useEffect, useState } from 'react';
import Container from '../../container';
import Text from '../../text';
import { Divider } from '@nextui-org/react';
import Footer from '../../footer';
import { IVehicleResourceShortProps } from '../../../props/drivers';
import DriverService from '../../../services/drivers';
import GlobalConfig from '../../../config';
import { useNavigate } from 'react-router-dom';

type TypeDriverVehicleVertificationProps = {
  children?: React.ReactNode;
  driverId: string
  vehicleId: string
};

const DriverVehicleVertification: React.FC<TypeDriverVehicleVertificationProps> = (props) => {
  const navigate = useNavigate();
  const [vehicle, setVehicle] = useState<IVehicleResourceShortProps>();
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    getVehicle()
    return () => { }
  }, [])

  const getVehicle = async () => {
    try {
      const item = await DriverService.vehicle.get(props.driverId, props.vehicleId)
      setVehicle(item)
    } catch (error) {

    }
  }

  return vehicle ? (
    <Container gap={10}>
      <Container>
        <Text size='xs'>Tipe Kendaraan</Text>
        <Text size='sm' weight='medium'>{vehicle.vehicle_category.vehicle_type}</Text>
      </Container>
      <Container>
        <Text size='xs'>Merk</Text>
        <Text size='sm' weight='medium'>{vehicle.brand}</Text>
      </Container>
      <Container>
        <Text size='xs'>Model</Text>
        <Text size='sm' weight='medium'>{vehicle.vehicle_name}</Text>
      </Container>
      <Container>
        <Text size='xs'>Warna</Text>
        <Text size='sm' weight='medium'>{vehicle.color}</Text>
      </Container>
      <Container>
        <Text size='xs'>Status</Text>
        <Text style={{ color: vehicle.is_verified ? '#007200' : '#B02E2C' }} size='sm' weight='medium'>{vehicle.is_verified ? 'Sudah Diverifikasi' : 'Belum Diverifikasi'}</Text>
      </Container>
      <Divider />
      <Container gap={20}>
        <Container gap={3}>
          <Text size='small' weight={'semibold'}>Foto STNK</Text>
          <img src={GlobalConfig.assetHostname + '/storage/' + vehicle.vehicle_certificate} style={{maxWidth: '250px'}} alt="stnk" />
        </Container>
        <Container gap={3}>
          <Text size='small' weight={'semibold'}>Foto Bukti Asuransi Kendaraan</Text>
          <img src={GlobalConfig.assetHostname + '/storage/' + vehicle.vehicle_insurance} style={{maxWidth: '250px'}} alt="stnk" />
        </Container>
        <Container gap={3}>
          <Text size='small' weight={'semibold'}>Tampak Depan Kendaraan</Text>
          <img src={GlobalConfig.assetHostname + '/storage/' + vehicle.vehicle_front_image} style={{maxWidth: '250px'}} alt="stnk" />
        </Container>
        <Container gap={3}>
          <Text size='small' weight={'semibold'}>Tampak Samping Kiri Kendaraan</Text>
          <img src={GlobalConfig.assetHostname + '/storage/' + vehicle.vehicle_side_left_image} style={{maxWidth: '250px'}} alt="stnk" />
        </Container>
        <Container gap={3}>
          <Text size='small' weight={'semibold'}>Tampak Samping Kanan Kendaraan</Text>
          <img src={GlobalConfig.assetHostname + '/storage/' + vehicle.vehicle_side_right_image} style={{maxWidth: '250px'}} alt="stnk" />
        </Container>
        <Container gap={3}>
          <Text size='small' weight={'semibold'}>Tampak Belakang Kendaraan</Text>
          <img src={GlobalConfig.assetHostname + '/storage/' + vehicle.vehicle_back_image} style={{maxWidth: '250px'}} alt="stnk" />
        </Container>
      </Container>
      <Footer buttons={[
        {
          key: 'create',
          color: 'success',
          width: '50%',
          children: "Terima",
          isLoading: submitting,
          onClick: async () => { 
            setSubmitting(true);
            await DriverService.vehicle.accept(vehicle.id + "");
            navigate("/drivers/" + vehicle.driver_id);
          }
        },
        {
          key: 'cancel',
          width: '50%',
          color: 'danger',
          children: "Tolak",
          isLoading: submitting,
          onClick: async () => { 
            setSubmitting(true);
            await DriverService.vehicle.reject(vehicle.id + "");
            navigate("/drivers/" + vehicle.driver_id);
          }
        }
      ]} />
    </Container>
  ) : <></>
};

export default DriverVehicleVertification;