import React, { useEffect } from 'react';
import Text from '../../text';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { Input, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Spinner } from "@nextui-org/react";
import Container from '../../container';
import styles from './styles.module.scss';
import Footer from '../../footer';
import { Circle, GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { IVehicleCategoryResourceShortProps } from '../../../props/vehicles/type';
import VehicleCategoryService from '../../../services/vehicle/category';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import GlobalConfig from '../../../config';
import ZoneService from '../../../services/zones/zone';


type ZoneFormProps = {
    zoneId?: number;
};

type ZoneDataProps = {
    name: string;
    coords?: google.maps.LatLngLiteral;
    radius: string;
    vehicleCategory?: IVehicleCategoryResourceShortProps;
}

const ZoneForm: React.FC<ZoneFormProps> = (props) => {
    const navigate = useNavigate();
    let centerPointMap: GoogleMap | null = null;

    const mapStatus = useJsApiLoader(GlobalConfig.googleMapConfig as any);

    const mode: 'create' | 'update' = props.zoneId ? 'update' : 'create';
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [data, setData] = React.useState<ZoneDataProps>({
        name: "",
        radius: ""
    });
    const [vehicleCategories, setVehicleCategories] = React.useState<IVehicleCategoryResourceShortProps[]>([]);
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    const _onMapLoaded = React.useCallback(function callback(map: any) {
        _onInitCameraMap(map);
    }, []);

    const _onInitCameraMap = (map: any) => {
        const bounds = new window.google.maps.LatLngBounds(data.coords || { lat: -6.207214132395497, lng: 106.84496438913546 });
        map.fitBounds(bounds);
    }

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _vehicleCategories = await VehicleCategoryService.retrieve();
        setVehicleCategories(_vehicleCategories.result);

        if (props.zoneId) {
            const _zone = (await ZoneService.read(props.zoneId)).result;
            const coords: google.maps.LatLngLiteral = {
                lat: Number(_zone.latitude),
                lng: Number(_zone.longitude)
            };

            setData({
                name: _zone.name,
                vehicleCategory: _vehicleCategories.result.find((vc) => vc.id === _zone.vehicle_category_id),
                radius: _zone.radius + '',
                coords
            });
        }

        setLoaded(true);
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const fd = new FormData();
            fd.append('name', data.name);
            fd.append('country_id', '1');
            fd.append('radius', data.radius);
            if (data.vehicleCategory) {
                fd.append('vehicle_category_id', data.vehicleCategory.id + "");
            }
            if (data.coords) {
                fd.append('latitude', data.coords.lat + "");
                fd.append('longitude', data.coords.lng + "");
            }

            if (mode === 'update' && props.zoneId) {
                await ZoneService.update(props.zoneId, fd);
            } else {
                await ZoneService.create(fd);
            }
            navigate('/zones');
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Container className={styles.container} style={{
            backgroundColor: 'white',
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 20,
            // alignItems: 'center',
            // justifyContent: 'center',
            // height: '80vh',
        }}>
        {!loaded ? <Spinner label='Memuat form ...' /> : null}
        {loaded ? <>
            <Container gap={15}>
                <Input label="Nama"
                    placeholder="Nama zona ..."
                    labelPlacement="outside"
                    value={data.name}
                    isReadOnly={submitting}
                    onValueChange={(value) => {
                        data.name = value || "";
                        setData({ ...data });
                    }} />
                <Container>
                    <Text size={'small'} weight='medium'>Titik Tengah Zona</Text>
                    {mapStatus.isLoaded ? <GoogleMap ref={(m) => centerPointMap = m} mapContainerStyle={{ height: 400, maxWidth: 720 }}
                        zoom={5}
                        onClick={(d) => {
                            if (d.latLng) {
                                data.coords = { lat: d.latLng.lat(), lng: d.latLng.lng() };
                                setData({ ...data });
                            }
                        }}
                        onLoad={_onMapLoaded} >
                        {data.coords ? <MarkerF position={data.coords} /> : null}
                        {data.coords ? <Circle
                            center={data.coords}
                            radius={Number(data.radius) * 1000}
                            options={{fillColor: '#006cbe5e', strokeColor: '#006cbe'}}
                        /> : null}
                    </GoogleMap> : null}
                </Container>
                <Input label="Latitude, Longitude"
                    placeholder="Pilih titik tengah zona"
                    labelPlacement="outside"
                    value={data.coords ? `${data.coords.lat}, ${data.coords.lng}` : ""}
                    isReadOnly={true} />
                <Input label={"Radius"}
                    inputMode='numeric'
                    labelPlacement="outside"
                    isReadOnly={submitting}
                    placeholder='Radius jangkauan zona ...'
                    value={data.radius}
                    onValueChange={(value) => {
                        data.radius = value || "";
                        setData({ ...data });
                    }}
                    endContent={<Text>km</Text>} />
                <Dropdown closeOnSelect={true} isDisabled={submitting}>
                    <DropdownTrigger>
                        <Input label={"Tipe Kendaraan"}
                            value={data.vehicleCategory ? data.vehicleCategory.vehicle_type : ''}
                            placeholder='Pilih tipe kendaraan ...'
                            labelPlacement="outside"
                            endContent={<FontAwesomeIcon icon={faChevronDown} />}
                            isReadOnly={true} />
                    </DropdownTrigger>
                    <DropdownMenu selectedKeys={data.vehicleCategory ? [data.vehicleCategory.id] : []}>
                        {vehicleCategories.map((cat) => {
                            return <DropdownItem key={cat.id} onClick={() => {
                                data.vehicleCategory = cat;
                                setData({ ...data });
                            }}>{cat.vehicle_type}</DropdownItem>;
                        })}
                    </DropdownMenu>
                </Dropdown>
            </Container>
            <Footer buttons={[
                {
                    key: 'create',
                    color: 'primary',
                    width: '70%',
                    children: mode === 'create' ? "Buat" : "Simpan",
                    isLoading: submitting,
                    onPress: _onSubmit
                },
                {
                    key: 'cancel',
                    width: '30%',
                    children: "Batal",
                    isDisabled: submitting,
                    onClick: () => navigate(-1)
                }
            ]} />
        </> : null}
    </Container>
};

export default ZoneForm;