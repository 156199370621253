import React, { useEffect, useState } from 'react';
import Container from '../container';
import { Button, Input } from '@nextui-org/react';
import { useParams } from 'react-router-dom';
import Text from '../text';
import { useNavigate } from "react-router-dom";
import AdminService from '../../services/admins';

type InputAdminDataType = {
  name?: string
  username?: string
  password?: string
  repeatPassword?: string  // Tambahkan repeatPassword ke dalam tipe data
}

type TypeAdminFormProps = {
  children?: React.ReactNode;
};

const AdminForm: React.FC<TypeAdminFormProps> = (props) => {
  const navigate = useNavigate();
  const { adminId } = useParams();

  const [data, setData] = useState<InputAdminDataType>({})
  const [submitting, setSubmitting] = useState<boolean>(false)

  useEffect(() => {
    getAdmin()
    return () => { }
  }, [])

  const getAdmin = async () => {
    if (adminId)
      try {
        const admin = await AdminService.get(adminId)
        setData({ name: admin.name, username: admin.username })
      } catch (error) {

      }
  }

  const onSave = async () => {
    if (data.password !== data.repeatPassword) {
      return;
    }

    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => value && value.trim() !== '' && formData.append(key, value));
    setSubmitting(true)
    try {
      !adminId ? await AdminService.create(formData) : await AdminService.update(formData, adminId)
      navigate('/admins')
    } catch (error) {
      setSubmitting(false)

    }
  }

  return (
    <Container gap={15} style={{
        backgroundColor: 'white',
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 20,
        // alignItems: 'center',
        // justifyContent: 'center',
        // height: '80vh',
      }}>
      <Text size='xl' weight='bold'>{adminId ? "Ubah Detail" : "Tambah"} Admin</Text>
      <Input
        value={data.name}
        onChange={e => setData({ ...data, name: e.target.value })}
        size='sm'
        placeholder="Nama lengkap admin ..."
        labelPlacement="outside"
      />
      <Input
        value={data.username}
        onChange={e => setData({ ...data, username: e.target.value })}
        size='sm'
        placeholder="Username untuk login ..."
        labelPlacement="outside"
      />
      {!adminId && (
        <>
          <Input
            value={data.password}
            onChange={e => setData({ ...data, password: e.target.value })}
            size='sm'
            placeholder="Password"
            type='password'
            labelPlacement="outside"
          />
          <Input
            value={data.repeatPassword}
            onChange={e => setData({ ...data, repeatPassword: e.target.value })}
            size='sm'
            placeholder="Ulangi Password"
            type='password'
            labelPlacement="outside"
          />
        </>
      )}
      <Container
        gap={10}
        direction='row'
        style={{ bottom: 0, width: '100%', padding: 20, background: 'white', position: "absolute", left: 0 }}>
        <Button disabled={submitting} size='sm' color='primary' style={{ flex: 1 }} onClick={onSave}>Simpan Perubahan</Button>
        <Button disabled={submitting} size='sm' onClick={() => navigate('/admins')}>Batal</Button>
      </Container>
    </Container>
  );
};

export default AdminForm;
