import React from 'react';
import Container from '../../../components/container';
import Text from '../../../components/text';
import styles from './styles.module.scss';
import OrderForm from '../../../components/orders/form';

type CreateOrderPageProps = {
  children?: React.ReactNode;
};

const CreateOrderPage: React.FC<CreateOrderPageProps> = (props) => {
  return <Container className={styles.container} gap={20}>
      <Text size={'xl'} weight='bold'>Buat Pesanan</Text>
      <OrderForm />
  </Container>;
};

export default CreateOrderPage;