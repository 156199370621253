import React from 'react';
import { NextUIProvider, Textarea } from "@nextui-org/react";
import MainPage from './pages';

type TypeAppProps = {
  children?: React.ReactNode;
};

const App: React.FC<TypeAppProps> = (props) => {
  return (
    <NextUIProvider>
      <MainPage />
    </NextUIProvider>
  )
};

export default App;