import React, { useEffect } from 'react';
import Text from '../../text';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Input, Button, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Spinner, Modal, ModalBody, ModalContent } from "@nextui-org/react";
import DropdownButton, { DropdownButtonSectionProps } from '../../buttons/dropdown';
import Container from '../../container';
import styles from './styles.module.scss';
import Footer from '../../footer';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import StationAreaService from '../../../services/station/area';
import { IVehicleCategoryResourceShortProps } from '../../../props/vehicles/type';
import VehicleCategoryService from '../../../services/vehicle/category';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import GlobalConfig from '../../../config';
import InputPlace, { SelectedLocationProps } from '../../inputs/place';
import InputPicker, { PickerItemProps } from '../../inputs/picker';
import DriverService from '../../../services/drivers';
import { IDriverResourceShort } from '../../../props/drivers';
import { IStationAreaResourceShortProps } from '../../../props/stations/area';
import { IStationQueueResourceShortProps } from '../../../props/stations/queue';
import GeneralLocationService from '../../../services/general/location';
import GeneralMapService from '../../../services/general/map';
import { IStationAreaPriceResourceShortProps } from '../../../props/stations/price';
import TripRequestService from '../../../services/trips/request';
import NumberService from '../../../services/general/number';
import StationQueueService from '../../../services/station/queue';
import moment from 'moment';
import { ITripRequestResourceShortProps } from '../../../props/trips/request';
import Banner from '../../banner';


type OrderFormProps = {
    orderId?: number;
};

type OrderDataProps = {
    pickup?: SelectedLocationProps;
    dropoff?: SelectedLocationProps;
    vehicleCategory?: IVehicleCategoryResourceShortProps;
    driver?: PickerItemProps;
    area?: IStationAreaResourceShortProps;
    queue?: IStationQueueResourceShortProps;
    distance: number;
    total: number;
    subtotal: number;
    surcharge: number;
    applicationFee: number;
    tax: number;
    parkingFee: number;
    otherFee: number;
    adminFee: number;
    pricePerKm: number;
}

const OrderForm: React.FC<OrderFormProps> = (props) => {
    let retryCounter: number = 0;

    const navigate = useNavigate();
    const mode: 'create' | 'update' = props.orderId ? 'update' : 'create';
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string | undefined>();
    const [data, setData] = React.useState<OrderDataProps>({
        distance: 0,
        total: 0,
        subtotal: 0,
        surcharge: 0,
        applicationFee: 0,
        tax: 0,
        parkingFee: 0,
        otherFee: 0,
        adminFee: 0,
        pricePerKm: 0
    });
    const [vehicleCategories, setVehicleCategories] = React.useState<IVehicleCategoryResourceShortProps[]>([]);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [areas, setAreas] = React.useState<IStationAreaResourceShortProps[]>([]);
    const [checkingPrice, setCheckingPrice] = React.useState<boolean>(false);
    const [request, setRequest] = React.useState<ITripRequestResourceShortProps | undefined>(undefined);
    const [canCancel, setCanCancel] = React.useState<boolean>(false);
    const [cancelling, setCancelling] = React.useState<boolean>(false);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _vehicleCategories = await VehicleCategoryService.retrieve();
        setVehicleCategories(_vehicleCategories.result);

        const _areas = await StationAreaService.retrieve();
        setAreas(_areas.result);

        setLoaded(true);
    }

    const getRequest = async (id: number) => {
        const _request = (await TripRequestService.get(id)).result;
        setRequest(_request);
        retryCounter += 1;
        
        if (retryCounter === 3) {
            setCanCancel(true);
        } else if (retryCounter > 10) {
            _onCancel();
        }

        if (retryCounter <= 10) {
            if (_request.status === 'requested') {
                setTimeout(() => {
                    getRequest(id);
                }, 5000);
            } else if (_request.status === 'cancelled' && submitting) {
                setRequest(undefined);
                setSubmitting(false);
                setCanCancel(false);
                setError("Driver membatalkan pesanan");
            } else {
                window.location.href = "/orders";
            }
        }
    }

    const _onCancel = async () => {
        if (request) {
            setCancelling(true);
            await TripRequestService.cancel(request.id);
            setRequest(undefined);
            setSubmitting(false);
            setCanCancel(false);
            setError("Driver tidak merespon");
            setCancelling(false);
            window.location.reload();
        }
    }

    const _onSubmit = async () => {
        try {
            const { pickup, dropoff, vehicleCategory, driver, area, queue, distance, total, subtotal, surcharge, applicationFee, tax, parkingFee, otherFee, adminFee, pricePerKm } = data;
            if (pickup && dropoff && vehicleCategory && area && driver) {
                setSubmitting(true);

                const fd = new FormData();
                fd.append('country_id', "1");
                fd.append('package_id', "0");
                fd.append('distance', distance + "");
                fd.append('vehicle_type', vehicleCategory.id + "");
                fd.append('pickup_address', pickup.name);
                fd.append('pickup_date', moment().toISOString());
                fd.append('pickup_lat', pickup.coords.lat + "");
                fd.append('pickup_lng', pickup.coords.lng + "");
                fd.append('drop_address', dropoff.name);
                fd.append('drop_lat', dropoff.coords.lat + "");
                fd.append('drop_lng', dropoff.coords.lng + "");
                fd.append('zone', "1");
                fd.append('payment_method', "1");
                fd.append('total', total + "");
                fd.append('sub_total', subtotal + "");
                fd.append('surcharge', surcharge + "");
                fd.append('application_fee', applicationFee + "");
                fd.append('tax', tax + "");
                fd.append('parking_fee', parkingFee + "");
                fd.append('other_fee', otherFee + "");
                fd.append('admin_fee', adminFee + "");
                fd.append('price_per_km', pricePerKm + "");
                fd.append('station_area_id', area.id + "");

                fd.append('driver_id', driver.data.driver.id);
                fd.append('vehicle_id', driver.data.vehicle_id);
                fd.append('queue_id', driver.data.id);

                fd.append('customer_id', '5');

                const _request = (await TripRequestService.create(fd)).result;
                setRequest(_request);
                getRequest(_request.id);
            }
        } catch (e) {
            setSubmitting(false);
        }
    }

    const setArea = (vehicle?: IVehicleCategoryResourceShortProps, pickup?: google.maps.LatLngLiteral) => {
        if (vehicle && pickup) {
            // find related area
            const relatedArea = areas.filter((area) => {
                return area.vehicle_category_id === vehicle.id;
            }).find((area) => {
                const distance = GeneralLocationService.getDistanceFromLatLonInKm(
                    { lat: Number(area.latitude), lng: Number(area.longitude) },
                    pickup
                );

                return distance <= area.radius;
            });
            data.area = relatedArea;
            setData({ ...data });
            setPrice(relatedArea, pickup, data.dropoff?.coords);
        }
    }

    const setPrice = async (area?: IStationAreaResourceShortProps, pickup?: google.maps.LatLngLiteral, dropoff?: google.maps.LatLngLiteral) => {
        if (area && pickup && dropoff) {
            setCheckingPrice(true);

            try {
                const routes = await GeneralMapService.getRoutes(pickup, dropoff);
                if (routes.legs[0]?.distance) {
                    data.distance = routes.legs[0]?.distance.value / 1000;
                } else {
                    data.distance = 0;
                }
            } catch (e) {
                data.distance = 0;
            }

            try {
                const pricing = (await TripRequestService.checkPrice(area.id, data.distance)).result;
                data.adminFee = pricing.admin_fee;
                data.applicationFee = pricing.application_fee;
                data.surcharge = pricing.surcharge;
                data.otherFee = pricing.other_fee;
                data.parkingFee = pricing.parking_fee;
                data.pricePerKm = pricing.price_per_km;
                data.subtotal = pricing.subtotal;
                data.total = pricing.total;

                setData({ ...data });
            } catch (e) {

            }

            setCheckingPrice(false);
        }
    }

    const _onPickupLocationChanged = (loc: SelectedLocationProps) => {
        data['pickup'] = loc;
        setData({ ...data });
        setArea(data.vehicleCategory, loc.coords);
    }

    return <Container className={styles.container}>
        {!loaded ? <Spinner label='Memuat form ...' /> : null}
        {loaded ? <>
            <Container gap={20}>
                {error ? <Banner color={'red'} text={error} /> : null}
                <Dropdown closeOnSelect={true} isDisabled={submitting}>
                    <DropdownTrigger>
                        <Input label={"Tipe Kendaraan"}
                            value={data.vehicleCategory ? data.vehicleCategory.vehicle_type : ''}
                            placeholder='Pilih tipe kendaraan ...'
                            labelPlacement="outside"
                            endContent={<FontAwesomeIcon icon={faChevronDown} />}
                            isReadOnly={true}
                            style={{ textAlign: 'left' }} />
                    </DropdownTrigger>
                    <DropdownMenu selectedKeys={data.vehicleCategory ? [data.vehicleCategory.id] : []}>
                        {vehicleCategories.map((cat) => {
                            return <DropdownItem key={cat.id} onClick={() => {
                                data.vehicleCategory = cat;
                                setData({ ...data });
                                setArea(cat, data.pickup?.coords);
                            }}>{cat.vehicle_type}</DropdownItem>;
                        })}
                    </DropdownMenu>
                </Dropdown>
                <InputPlace label={'Pilih Lokasi Penjemputan'} selected={data.pickup} onSelectLocation={_onPickupLocationChanged} />
                {data.area ? <Container>
                    <Text size={'small'} weight='medium'>Area/Kawasan Terkait</Text>
                    <Text>{data.area.name}</Text>
                </Container> : null}
                <InputPlace label={'Pilih Lokasi Pengantaran'} selected={data.dropoff} onSelectLocation={(loc) => {
                    data['dropoff'] = loc;
                    setData({ ...data });
                    setPrice(data.area, data.pickup?.coords, loc?.coords);
                }} />
                <Container gap={3}>
                    <Text size={'small'} weight='medium'>Harga Total</Text>
                    <Container style={{ padding: 10, backgroundColor: 'green', color: '#fff', borderRadius: 10 }}>
                        {!checkingPrice ? <Text size={'large'} weight='semibold'>Rp. {NumberService.getNumberWithSeparator(data.total)}</Text> : null}
                        {checkingPrice ? <Spinner color='white' /> : null}
                    </Container>
                </Container>
                {data.area && data.vehicleCategory ? <InputPicker label={"Pilih Driver"} selected={data.driver} noItemsText='Belum ada driver absen di area ini' onSelect={(item) => {
                    data['driver'] = item;
                    setData({ ...data });
                }} onDelete={() => {
                    data['driver'] = undefined;
                    setData({ ...data });
                }} onSearch={async (keyword) => {
                    if (data.area) {
                        const qs: string[] = [];
                        if (data.vehicleCategory) {
                            qs.push(`vehicle_type=${data.vehicleCategory.id}`);
                        }

                        const queues = (await StationQueueService.retrieve(data.area?.id, qs.join("&"))).result;
                        return queues.map((queue) => {
                            const driver = queue.driver;
                            const name = [driver.first_name];
                            if (driver.last_name) { name.push(driver.last_name); }
                            return {
                                key: driver.id + "",
                                title: name.join(" "),
                                description: driver.phone_with_code,
                                data: queue
                            };
                        })
                    } else {
                        return [];
                    }
                }} /> : null}
            </Container>
            <Footer buttons={[
                {
                    key: 'create',
                    color: 'primary',
                    width: '70%',
                    children: mode === 'create' ? "Buat" : "Simpan",
                    isLoading: submitting,
                    isDisabled: !data.driver || checkingPrice,
                    onPress: _onSubmit
                },
                {
                    key: 'cancel',
                    width: '30%',
                    children: "Batal",
                    isDisabled: submitting,
                    onClick: () => navigate(-1)
                }
            ]} />
        </> : null}
        <Modal isOpen={submitting && request !== undefined}>
            <ModalContent>
                <ModalBody>
                    <Container gap={20}>
                        <Spinner label={!canCancel ? "Menunggu respon dari driver ..." : "Driver memakan waktu lebih lama untuk merespon ..."} />
                        {canCancel ? <Button isLoading={cancelling} onPress={_onCancel}>Batalkan Pesanan</Button> : null}
                    </Container>
                </ModalBody>
            </ModalContent>
        </Modal>
    </Container>
};

export default OrderForm;