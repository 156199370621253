import React from 'react';
import Text from '../../text';
import { Modal, ModalContent, ModalHeader, ModalBody, avatar, Input, ModalFooter, Button, Textarea } from '@nextui-org/react';
import Container from '../../container';
import { ITripResourceShortProps } from '../../../props/trips';
import TripService from '../../../services/trips';

type TypeCancelOrderModalProps = {
  children?: React.ReactNode;
  order: ITripResourceShortProps;
  onClose(refresh?: boolean): void;
};

type FormDataProps = {
  justification: string;
}

const CancelOrderModal: React.FC<TypeCancelOrderModalProps> = (props) => {
  const {order} = props;
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [data, setData] = React.useState<FormDataProps>({
    justification: ""
  });

  const _onCancel = async () => {
    try {
      setSubmitting(true);
      const fd = new FormData();
      fd.append("justification", data.justification);

      await TripService.cancel(order.id, fd);
      props.onClose(true);
    } catch(e) {

    }
  }

  return (
    <Modal size='sm' isOpen={true}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">Batalkan Pesanan</ModalHeader>
        <ModalBody>
          <Container gap={20}>
            <Text size='small'>Apakah anda yakin untuk membatalkan pesanan dibawah ini?</Text>
            <Container gap={20}>
              <Container gap={3}>
                <Text weight='medium' size='small'>Driver</Text>
                <Text size={'small'}>{order.driver.first_name + " " + order.driver.last_name}</Text>
              </Container>
              {order.customer ? <Container gap={3}>
                <Text weight='medium' size='small'>Customer</Text>
                <Text size={'small'}>{order.customer.first_name + " " + order.customer.last_name}</Text>
              </Container> : null}
              {!order.customer && order.sales ? <Container gap={3}>
                <Text weight='medium' size='small'>Customer</Text>
                <Text size={'small'}>{order.sales.name}</Text>
              </Container> : null}
              <Container gap={3}>
                <Text weight='medium' size='small'>Alamat Penjemputan</Text>
                <Text size={'small'}>{order.pickup_address}</Text>
              </Container>
              <Container gap={3}>
                <Text weight='medium' size='small'>Alamat Tujuan</Text>
                <Text size={'small'}>{order.drop_address}</Text>
              </Container>
              <Textarea
                isRequired={true}
                isReadOnly={submitting}
                label="Alasan"
                placeholder="Alasan mengapa pesanan ini dibatalkan ..."
                labelPlacement="outside"
                value={data.justification}
                onValueChange={(value) => {
                  data.justification = value;
                  setData({...data});
                }}
              />
            </Container>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color='danger' onPress={_onCancel} isLoading={submitting} style={{ width: '70%' }}>
            Batalkan Pesanan
          </Button>
          <Button isDisabled={submitting} onPress={() => props.onClose()} style={{ width: '30%' }}>
            Batal
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
};

export default CancelOrderModal;