import React from 'react';
import Container from '../../../../components/container';
import { useParams } from 'react-router-dom';
import styles from '../styles.module.scss'
import DriverVerifyTopUp from '../../../../components/drivers/topup/verify';

type DriverTopUpDetailsPageProps = {
  children?: React.ReactNode;
};

const DriverTopUpDetailsPage: React.FC<DriverTopUpDetailsPageProps> = (props) => {
  const { referenceId } = useParams();

  return (
    <Container className={styles.container}>
      {referenceId ? <DriverVerifyTopUp referenceId={referenceId} /> : null}
    </Container>
  )
};

export default DriverTopUpDetailsPage;