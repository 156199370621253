import React from 'react';
import AreaForm from '../../../../components/areas/form';
import Container from '../../../../components/container';
import Text from '../../../../components/text';
import styles from './../styles.module.scss';
import AreaPriceForm from '../../../../components/areas/prices/form';
import { useParams } from 'react-router-dom';

type CreateAreaPricePageProps = {
    children?: React.ReactNode;
};

const CreateAreaPricePage: React.FC<CreateAreaPricePageProps> = (props) => {
    const { areaId } = useParams();

    return <Container className={styles.container} gap={20}>
        <Text size={'xl'} weight='bold'>Buat Harga</Text>
        {areaId ? <AreaPriceForm areaId={Number(areaId)} /> : null}
    </Container>;
};

export default CreateAreaPricePage;