import React, { useEffect } from 'react';
import Text from '../../text';
import { faChevronDown, faSearch, faTrash } from '@fortawesome/pro-light-svg-icons';
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Input, Button, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Spinner, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Textarea } from "@nextui-org/react";
import DropdownButton, { DropdownButtonSectionProps } from '../../buttons/dropdown';
import Container from '../../container';
import styles from './styles.module.scss';
import Footer from '../../footer';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import StationAreaService from '../../../services/station/area';
import { IVehicleCategoryResourceShortProps } from '../../../props/vehicles/type';
import VehicleCategoryService from '../../../services/vehicle/category';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import GlobalConfig from '../../../config';


type InputPlaceProps = {
    selected?: SelectedLocationProps;
    label?: string;
    isRequired?: boolean;
    modalHeader?: string;
    selectButtonText?: string;
    onSelectLocation?(location?: SelectedLocationProps): void;
};

type PlaceSuggestionProps = {
    key: string;
    title: string;
    description?: string;
}

export type SelectedLocationProps = {
    key: string;
    name: string;
    coords: google.maps.LatLngLiteral;
}

const InputPlace: React.FC<InputPlaceProps> = (props) => {
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [keyword, setKeyword] = React.useState<string>("");
    const [map, setMap] = React.useState<google.maps.Map | undefined>();
    const [suggestions, setSuggestions] = React.useState<PlaceSuggestionProps[]>([]);
    const [searching, setSearching] = React.useState<boolean>(false);
    const [selected, setSelected] = React.useState<SelectedLocationProps | undefined>();
    const [current, setCurrent] = React.useState<SelectedLocationProps | undefined>();
    const [gettingDetails, setGettingDetails] = React.useState<boolean>(false);

    useEffect(() => {
        setSelected(props.selected);
        setCurrent(props.selected);
    }, [props.selected])

    const mapStatus = useJsApiLoader(GlobalConfig.googleMapConfig as any);

    const _onCallQueryPrediction = async (keyword: string, region?: string): Promise<google.maps.places.QueryAutocompletePrediction[]> => {
        return new Promise((resolve, reject) => {
            var service = new google.maps.places.AutocompleteService();
            var request: google.maps.places.QueryAutocompletionRequest = { input: keyword };
            service.getQueryPredictions(request, function (results, status) {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    resolve(results || []);
                } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
                    resolve([]);
                } else {
                    reject(status);
                }
            });
        })
    }

    const _onCallAutoCompleteService = async (keyword: string, region?: string): Promise<google.maps.places.AutocompletePrediction[]> => {
        return new Promise((resolve, reject) => {
            var service = new google.maps.places.AutocompleteService();
            var request: google.maps.places.AutocompletionRequest = { input: keyword, language: 'en', types: ['geocode'], region };
            service.getPlacePredictions(request, function (results, status) {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    resolve(results || []);
                } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
                    resolve([]);
                } else {
                    reject(status);
                }
            });
        })
    }

    const _onCallGeocoderService = async (keyword: string, region?: string): Promise<google.maps.GeocoderResult[]> => {
        return new Promise((resolve, reject) => {
            var service = new google.maps.Geocoder();
            var request: google.maps.GeocoderRequest = { address: keyword, region: region };
            service.geocode(request, function (results, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                    resolve(results || []);
                } else if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
                    resolve([]);
                } else {
                    reject(status);
                }
            });
        })
    }

    const _onSelectLocationFromMap = async (latLng: google.maps.LatLngLiteral) => {
        return new Promise((resolve, reject) => {
            setGettingDetails(true);
            var service = new google.maps.Geocoder();
            var request: google.maps.GeocoderRequest = { location: latLng };
            service.geocode(request, function (result, status) {
                if (status === google.maps.GeocoderStatus.OK && result && result[0]) {
                    setCurrent({
                        key: result[0].place_id,
                        name: result[0].formatted_address || "",
                        coords: latLng
                    })
                    setGettingDetails(false);
                }
            });
        })
    }

    const _onSearch = async (keyword: string) => {
        setKeyword(keyword);
        if (map && keyword.length > 0) {
            setSearching(true);
            const response: any = await Promise.all([_onCallAutoCompleteService(keyword, "ID"), _onCallGeocoderService(keyword, 'ID'), _onCallQueryPrediction(keyword)]);
            const results = [...response[0], ...response[1], ...response[2]];

            setSuggestions(results.map((res) => {
                return {
                    key: res.place_id,
                    title: res.structured_formatting ? res.structured_formatting.main_text : res.formatted_address,
                    description: res.structured_formatting?.secondary_text
                };
            }))
            setSearching(false);
        } else {
            setSuggestions([]);
            setSearching(false);
        }
    }

    const _onSelectPlace = async (placeId: string) => {
        if (map) {
            var service = new google.maps.places.PlacesService(map);
            const request: google.maps.places.PlaceDetailsRequest = {
                placeId,
                fields: ["formatted_address", "geometry", "name"]
            }
            service.getDetails(request, function (result, status) {
                if (status === google.maps.places.PlacesServiceStatus.OK && result?.geometry?.location) {
                    const coords = { lat: result.geometry.location.lat(), lng: result.geometry.location.lng() };
                    const name = [];
                    if (result.name) {name.push(result.name)}
                    if (result.formatted_address) {name.push(result.formatted_address)}

                    setCurrent({
                        key: result.place_id || placeId,
                        name: name.join(" - "),
                        coords
                    });
                    setKeyword("");
                } else {
                    setCurrent(undefined);
                }
            })
        }
    }

    const _onSubmit = () => {
        if (props.onSelectLocation && current) {
            props.onSelectLocation(current);
            setShowModal(false);
        }
    }

    const _onClearSelection = () => {
        if (props.onSelectLocation) {
            props.onSelectLocation();
        }
    }

    const _onCancel = () => {
        setCurrent(selected);
        setShowModal(false);
    }

    return <div>
        <Container className={styles.container}>
            {props.label ? <Text size={'small'} weight='medium'>{props.label}</Text> : null}
            <Container direction='row' gap={10}>
                {selected ? <Textarea style={{ flexGrow: 1 }} value={`${selected.name} (${selected.coords.lat}, ${selected.coords.lng})`} isReadOnly={true} isRequired={props.isRequired} onClick={() => setShowModal(true)} /> : null}
                {!selected ? <Button fullWidth={false} style={{minWidth: 150, marginTop: 3}} color={'primary'} onPress={() => setShowModal(true)}>Pilih Lokasi</Button> : null}
                {selected ? <Button isIconOnly={true} fullWidth={false} color={'default'} style={{top: 5}} onPress={_onClearSelection}><FontAwesomeIcon icon={faTrash} /></Button> : null}
            </Container>
        </Container>
        <Modal isOpen={showModal} size={'full'} onOpenChange={() => setShowModal(false)}>
            <ModalContent className={styles.searchModal}>
                <ModalHeader className="flex flex-col gap-1">{props.modalHeader || 'Pilih Lokasi'}</ModalHeader>
                <ModalBody>
                    <Input placeholder='Cari lokasi ...' value={keyword} onValueChange={_onSearch} endContent={<FontAwesomeIcon icon={faSearch} />} />
                    {keyword.length < 1 && current ? null : <div className={styles.suggestionsBox}>
                        {searching ? <div style={{ padding: 20, textAlign: 'center' }}><Spinner label='Mencari lokasi ...' /></div> : null}
                        {!searching && suggestions.length < 1 ? <div style={{ padding: 20, textAlign: 'center' }}><Text size={'small'}>Lokasi tidak ditemukan</Text></div> : null}
                        {!searching && suggestions.length > 0 ? suggestions.map((sug) => {
                            return <div onClick={() => _onSelectPlace(sug.key)} key={sug.key} className={styles.suggestionItem}>
                                <Text size={'small'} weight='semibold'>{sug.title}</Text>
                                {sug.description ? <Text size='tiny'>{sug.description || ""}</Text> : null}
                            </div>
                        }) : null}
                    </div>}
                    {mapStatus.isLoaded ? <GoogleMap mapContainerStyle={{ height: 200, maxWidth: 720 }}
                        zoom={13}
                        options={{
                            disableDefaultUI: true
                        }}
                        center={current?.coords || { lat: -6.207214132395497, lng: 106.84496438913546 }}
                        onClick={(d) => {
                            if (d.latLng) {
                                _onSelectLocationFromMap({lat: d.latLng.lat(), lng: d.latLng.lng()});
                            }
                        }}
                        onLoad={(_map) => setMap(_map)} >
                        {current ? <MarkerF position={current.coords} /> : null}
                    </GoogleMap> : null}
                    <Container>
                        <Text size={'small'} weight='semibold'>{props.label || "Lokasi Terpilih"}</Text>
                        {gettingDetails ? <Spinner size={'sm'} /> : null}
                        {!gettingDetails ? <Text size={'small'}>{current?.name || "-"}</Text> : null}
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button fullWidth={true} color={'primary'} onClick={_onSubmit}>{props.selectButtonText || "Gunakan Lokasi"}</Button>
                    <Button style={{minWidth: 120}} color={'default'} onClick={_onCancel}>Batal</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </div>;

};

export default InputPlace;