import { ITripResourceShortProps, ITripHistoriesResourceShortProps } from '../../props/trips';
import Request from './../general/request';

const TripService = {
    retrieve: async (qs?: string) => {
      try {
        const response = await Request.get<ITripResourceShortProps[]>(`/api/admin/trips?${qs || ""}`);
        return response;
      } catch (error) {
        throw error
      }
    },
    retrieveNew: async (qs?: string) => {
      try {
        const response = await Request.get<ITripHistoriesResourceShortProps>(`/api/admin/trips_histories?${qs || ""}`);
        return response;
      } catch (error) {
        throw error
      }
    },
    cancel: async (tripId: number, data: FormData) => {
      try {
        const response = await Request.post<ITripResourceShortProps>(`/api/admin/trips/${tripId}/cancel`, data);
        return response;
      } catch (error) {
        throw error
      }
    },
    finish: async (driverId: number, tripId: number) => {
      try {
        const response = await Request.get<ITripResourceShortProps>(`/api/admin/drivers/${driverId}/trips/${tripId}/finish`);
        
        return response;
      } catch (error) {
        throw error
      }
    },
    getStatusData: (status: string): {text: string, color: 'danger' | 'default' | 'primary' | 'success' | 'warning' | 'secondary'} => {
      if (status === 'picking_up') {
        return {
          text: 'Menjemput Penumpang',
          color: 'warning'
        }
      } else if (status === 'dropping_off') {
        return {
          text: 'Mengantarkan Penumpang',
          color: 'warning'
        }
      } else if (status === 'completed') {
        return {
          text: 'Pesanan Selesai',
          color: 'success'
        }
      } else if (status === 'cancelled') {
        return {
          text: 'Pesanan Dibatalkan',
          color: 'danger'
        }
      } else {
        return {
          text: 'Error',
          color: 'danger'
        }
      }
    },
    analytics: {
      totalIncomes: (trips: ITripResourceShortProps[]) => {
        let incomes = 0;
        let adminFee = 0;
        let applicationFee = 0;

        trips.forEach((trip) => {
          incomes += Number(trip.total);
          adminFee += Number(trip.admin_fee);
          applicationFee += Number(trip.application_fee);
        });
        
        return {
          incomes,
          adminFee,
          applicationFee
        };
      }
    }
};

export default TripService;