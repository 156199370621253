import React from 'react';
import { useParams } from 'react-router-dom';
import Container from '../../../../components/container';
import styles from './../styles.module.scss';
import ZonePriceDetails from '../../../../components/zones/prices/details';

type ZonePriceDetailsPageProps = {
    children?: React.ReactNode;
};

const ZonePriceDetailsPage: React.FC<ZonePriceDetailsPageProps> = (props) => {
    const { zoneId, priceId } = useParams();

    return <Container className={styles.container} gap={20}>
      <ZonePriceDetails zoneId={Number(zoneId)} priceId={Number(priceId)} />
    </Container>;
};

export default ZonePriceDetailsPage;