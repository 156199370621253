import React from 'react';
import Container from '../../../../components/container';
import Text from '../../../../components/text';
import styles from './../styles.module.scss';
import { useParams } from 'react-router-dom';
import ZonePriceForm from '../../../../components/zones/prices/form';

type CreateZonePricePageProps = {
    children?: React.ReactNode;
};

const CreateZonePricePage: React.FC<CreateZonePricePageProps> = (props) => {
    const { zoneId } = useParams();

    return <Container className={styles.container} gap={20}>
        <Text size={'xl'} weight='bold'>Buat Harga</Text>
        {zoneId ? <ZonePriceForm zoneId={Number(zoneId)} /> : null}
    </Container>;
};

export default CreateZonePricePage;