const GeneralMapService = {
    getRoutes: (origin: google.maps.LatLngLiteral, destination: google.maps.LatLngLiteral): Promise<google.maps.DirectionsRoute> => {
        return new Promise((resolve, reject) => {
            const directionService = new google.maps.DirectionsService();
            const request: google.maps.DirectionsRequest = {
                origin,
                destination,
                travelMode: google.maps.TravelMode.DRIVING
            };
    
            directionService.route(request, (response, status) => {
                if (status === google.maps.DirectionsStatus.OK && response) {
                    if (response.routes[0]) {
                        resolve(response.routes[0]);
                    } else {
                        reject("No available routes between these 2 locations");
                    }
                } else if (status === google.maps.DirectionsStatus.OK && !response) {
                    reject("No available routes between these 2 locations");
                } else {
                    reject(status);
                }
            });
        })
    }
}

export default GeneralMapService;