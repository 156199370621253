import { ITripRequestCheckPriceProps, ITripRequestResourceShortProps } from '../../props/trips/request';
import Request from './../general/request';

const TripRequestService = {
    checkPrice: async (areaId: number, distance: number) => {
        try {
            const response = await Request.get<ITripRequestCheckPriceProps>(`/api/admin/trips/requests/check_price?area_id=${areaId}&distance=${distance}`);
            return response;
        } catch (error) {
            throw error
        }
    },
    create: async (data: FormData) => {
      try {
        const response = await Request.post<ITripRequestResourceShortProps>('/api/admin/trips/requests', data);
        return response;
      } catch (error) {
        throw error
      }
    },
    cancel: async (requestId: number) => {
      try {
        const fd = new FormData();
        const response = await Request.post<ITripRequestResourceShortProps>(`/api/admin/trips/requests/${requestId}/cancel`, fd);
        return response;
      } catch (error) {
        throw error
      }
    },
    get: async (requestId: number) => {
      try {
        const response = await Request.get<ITripRequestResourceShortProps>(`/api/admin/trips/requests/${requestId}`);
        return response;
      } catch (error) {
        throw error
      }
    }
};

export default TripRequestService;