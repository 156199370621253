import React from 'react';
import Container from '../../../components/container';
import styles from './styles.module.scss';
import AdminForm from '../../../components/admins/form';
import { useLocation } from 'react-router-dom';
import AdminFormUpdatePassword from '../../../components/admins/updatePassword';

type TypeFormAdminPageProps = {
  children?: React.ReactNode;
};

const FormAdminPage: React.FC<TypeFormAdminPageProps> = (props) => {
  const location = useLocation();
  const isUpdatePassword = location.pathname.includes('update_password');

  return (
    <Container className={styles.container} gap={20} style={{ height: 'calc(100vh - 60px)', position: 'relative' }}>
      {isUpdatePassword ? <AdminFormUpdatePassword /> : <AdminForm />}
    </Container>
  );
};

export default FormAdminPage;
