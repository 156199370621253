import React, { useEffect } from 'react';
import Text from '../../text';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { Button, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input, Select, SelectItem, Spinner, Textarea } from "@nextui-org/react";
import DropdownButton from '../../buttons/dropdown';
import Container from '../../container';
import { useJsApiLoader } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import GlobalConfig from '../../../config';
import { IDriverResourceShort } from '../../../props/drivers';
import DriverService from '../../../services/drivers';
import TopUpMethods from '../../../manifests/topUpMethods';
import Footer from '../../footer';
import NumberService from '../../../services/general/number';
import WithdrawMethods, { WithdrawMethodProps } from '../../../manifests/withdrawMethod';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


type DriverWithdrawCreditProps = {
    driverId: string;
};

type FormDataProps = {
    amount: string;
    reason: string;
    proof?: any;
    method: WithdrawMethodProps;
}

type FormErrorProps = {
    amount?: string;
    reason?: string;
    proof?: string;
}

const DriverWithdrawCredit: React.FC<DriverWithdrawCreditProps> = (props) => {
    const navigate = useNavigate();

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [driver, setDriver] = React.useState<IDriverResourceShort | undefined>();
    const [data, setData] = React.useState<FormDataProps>({ amount: '', reason: '', method: WithdrawMethods[0] });
    const [error, setError] = React.useState<FormErrorProps>({});
    const [pin, setPin] = React.useState<string>("");
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _driver = (await DriverService.read(props.driverId)).result;
        setDriver(_driver);
        setLoaded(true);
    }

    const renderProofImage = () => {
        if (data.proof) {
            const src = URL.createObjectURL(data.proof);
            return <img src={src} style={{ maxWidth: 200 }} />
        }
        return <></>;
    }

    const canSubmit = () => {
        if (data.amount === "" || data.reason === "" || !data.proof) {
            return false;
        } else if (error.amount || error.reason || error.proof) {
            return false;
        }

        return true;
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const fd = new FormData();
            fd.append("driver_id", props.driverId);
            fd.append("amount", data.amount);
            fd.append("reason", data.reason);
            fd.append('pin', pin + "");
            fd.append("proof", data.proof);
            fd.append("payment_method", data.method.method);

            const withdraw = (await DriverService.wallet.withdraw.request(fd));
            
            setSubmitting(false); // Reset loading state
            if(withdraw.message=='KODE AKSES DITOLAK!'){
                alert('KODE AKSES TIDAK BENAR!');
            }else {
                navigate(`/drivers/topup/${withdraw.result.reference_id}/details`);
            }

        } catch(e) {
            setSubmitting(false);
        }
    }

    return <Container gap={20} style={{
            backgroundColor: 'white',
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 20,
            // alignItems: 'center',
            // justifyContent: 'center',
            // height: '80vh',
        }} >
        {!loaded ? <Spinner label='Memuat detail ...' /> : null}
        {loaded && driver ? <>
            <Container direction='row' justifyContent='space-between' alignItems='center'>
                <Text size={'xl'} weight='bold'>Tarik Saldo Driver</Text>
            </Container>
            <Container gap={20}>
                <Text weight='medium' style={{ color: '#a20000' }}>!!! PASTIKAN NAMA DAN NOMOR HANDPHONE DRIVER TELAH SESUAI</Text>
                <Container gap={3}>
                    <Text size={'small'} weight='semibold'>Driver</Text>
                    <Text size={'small'}>{driver.first_name} {driver.last_name || ""} ({driver.phone_with_code})</Text>
                </Container>
                <Container gap={3}>
                    <Text size={'small'} weight='semibold'>Saldo Saat Ini</Text>
                    <Text size={'small'}>Rp. {NumberService.getNumberWithSeparator(driver.wallet)}</Text>
                </Container>
                <Divider />
                <Container>
                    <Dropdown closeOnSelect={true} isDisabled={submitting}>
                        <DropdownTrigger>
                            <Input label={"Metode Pembayaran"}
                                value={data.method ? data.method.text : ''}
                                placeholder='Pilih metode pembayaran ...'
                                labelPlacement="outside"
                                endContent={<FontAwesomeIcon icon={faChevronDown} />}
                                isReadOnly={true} />
                        </DropdownTrigger>
                        <DropdownMenu selectedKeys={data.method ? [data.method.key] : []}>
                            {WithdrawMethods.map((wm) => {
                                return <DropdownItem key={wm.key} onClick={() => {
                                    data.method = wm;
                                    setData({ ...data });
                                }}>{wm.text}</DropdownItem>;
                            })}
                        </DropdownMenu>
                    </Dropdown>
                </Container>
                <Container style={{ gap: 2 }}>
                    <Textarea label={'Alasan penarikan'}
                        labelPlacement='outside'
                        value={data.reason}
                        required={true}
                        isRequired={true}
                        onValueChange={(value) => {
                            data.reason = value;
                            if ((value || "").trim() === "") {
                                error.reason = "Alasan penarikan tidak boleh kosong."
                            } else {
                                error.reason = undefined;
                            }

                            setData({ ...data });
                            setError({ ...error });
                        }} />
                    {error.reason ? <Text size={'small'} style={{ color: '#a20000' }}>{error.reason}</Text> : null}
                </Container>
                <Container style={{ gap: 4 }}>
                    <Text size={'small'} weight='medium'>Bukti Pembayaran</Text>
                    <input type="file"
                        onChange={(evt: any) => {
                            const value = evt?.target?.files ? evt?.target?.files[0] : undefined;
                            if (!value) {
                                error.proof = "Bukti transaksi tidak boleh kosong"
                            } else {
                                error.proof = undefined;
                            }

                            data.proof = value;
                            setData({ ...data });
                            setError({ ...error });
                        }}
                        accept="image/*" />
                    {renderProofImage()}
                </Container>
                <Container style={{ gap: 2 }}>
                    <Input label={'Nominal'}
                        labelPlacement='outside'
                        value={data.amount}
                        required={true}
                        isRequired={true}
                        inputMode='numeric'
                        placeholder='Nominal yang ditarik'
                        onValueChange={(value) => {
                            const isNumber = !isNaN(Number(value));

                            if ((value || "").trim() === "") {
                                error.amount = "Nominal penarikan tidak boleh kosong."
                            } else if (isNumber && Number(value) > driver.wallet) {
                                error.amount = "Nominal penarikan melebiha saldo driver saat ini"
                            } else {
                                error.amount = undefined;
                            }

                            if (isNumber) {
                                data.amount = value;
                                setData({ ...data });
                            }

                            setError({ ...error });
                        }}
                        startContent={<Text size={'small'}>Rp. </Text>} />
                    {error.amount ? <Text size={'small'} style={{ color: '#a20000' }}>{error.amount}</Text> : null}
                </Container>

                <Divider style={{marginBottom:30}} />

                <Container style={{width:'100%',display:'flex', alignItems:'end', justifyContent:'right'}}>
                    <Input  label={'Masukkan kode akses Anda'} 
                        labelPlacement='outside'
                        inputMode='none'
                        placeholder='Masukkan kode akses yang benar'
                        onValueChange={setPin}
                        />
                </Container>



                <Container>
                    <Button color="primary" onPress={_onSubmit}  isDisabled= {!canSubmit()} isLoading={submitting} style={{ width: '100%' }}>
                        Tarik Saldo
                    </Button>
                </Container>

            </Container>
            {/* <Footer buttons={[
                {
                    key: 'topup',
                    color: 'primary',
                    children: "Tarik Saldo",
                    isLoading: submitting,
                    isDisabled: !canSubmit(),
                    onPress: _onSubmit
                }
            ]} /> */}
        </> : null}
    </Container>
};

export default DriverWithdrawCredit;