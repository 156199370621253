import React from 'react';
import { useParams } from 'react-router-dom';
import Container from '../../../../components/container';
import styles from './../styles.module.scss';
import AreaPriceDetails from '../../../../components/areas/prices/details';

type AreaPriceDetailsPageProps = {
    children?: React.ReactNode;
};

const AreaPriceDetailsPage: React.FC<AreaPriceDetailsPageProps> = (props) => {
    const { areaId, priceId } = useParams();

    return <Container className={styles.container} gap={20}>
      <AreaPriceDetails areaId={Number(areaId)} priceId={Number(priceId)} />
    </Container>;
};

export default AreaPriceDetailsPage;