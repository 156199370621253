import React, { useEffect, useState } from 'react';
import Container from '../container';
import { Button, Input } from '@nextui-org/react';
import { useParams } from 'react-router-dom';
import Text from '../text';
import { useNavigate } from "react-router-dom";
import AdminService from '../../services/admins';

type TypeAdminFormUpdatePasswordProps = {
  children?: React.ReactNode;
};

const AdminFormUpdatePassword: React.FC<TypeAdminFormUpdatePasswordProps> = (props) => {
  const navigate = useNavigate();
  const { adminId } = useParams();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [passwordsMatch, setPasswordsMatch] = useState<boolean>(true);

  const onSave = async () => {
    if (adminId && password === repeatPassword) {
      const formData = new FormData();
      formData.append('password', password);
      setSubmitting(true);
      try {
        await AdminService.update(formData, adminId);
        navigate('/admins');
      } catch (error) {
        setSubmitting(false);
      }
    } else {
      // Passwords tidak cocok, tampilkan pesan kesalahan
      setPasswordsMatch(false);
    }
  }

  return (
    <Container gap={15}>
      <Text size='xl' weight='bold'>Ubah Password</Text>
      <Input
        value={password}
        onChange={e => setPassword(e.target.value)}
        size='sm'
        placeholder='password'
        labelPlacement="outside"
      />
      <Input
        value={repeatPassword}
        onChange={e => setRepeatPassword(e.target.value)}
        size='sm'
        placeholder="Ulangi Password"
        type='password'
        labelPlacement="outside"
      />
      {!passwordsMatch && <p style={{ color: 'red' }}>Password tidak cocok.</p>}
      <Container
        gap={10}
        direction='row'
        style={{ bottom: 0, width: '100%', padding: 20, background: 'white', position: "absolute", left: 0 }}>
        <Button disabled={submitting} size='sm' color='primary' style={{ flex: 1 }} onClick={onSave}>Simpan Perubahan</Button>
        <Button disabled={submitting} size='sm' onClick={() => navigate('/admins')}>Batal</Button>
      </Container>
    </Container>
  );
};

export default AdminFormUpdatePassword;
