import React, { useEffect } from 'react';
import Container from '../container';
import Text from '../text';
import styles from './styles.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faSearch } from '@fortawesome/pro-light-svg-icons';
import { Chip, Input, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownSection, DropdownTrigger, Modal, ModalBody, ModalContent, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react';
import { ICustomerResourceShort } from '../../props/customers';
import CustomerService from '../../services/customers';
import DropdownButton, { DropdownButtonSectionProps } from '../buttons/dropdown';
import { NavLink, useNavigate } from 'react-router-dom';
import NumberService from '../../services/general/number';

type TypeListCustomerProps = {
  children?: React.ReactNode;
};

const ListCustomer: React.FC<TypeListCustomerProps> = (props) => {
  const navigate = useNavigate();

  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [customers, setCustomers] = React.useState<ICustomerResourceShort[]>([]);
  const [updating, setUpdating] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>();

  useEffect(() => {
    init();
    return () => { }
  }, [search]);

  // const init = async () => {
  //   const _customers = (await CustomerService.retrieve());
  //   setCustomers(_customers);
  //   setLoaded(true);
  // }

  const init = async () => {
    const qs: string[] = [];
		if (search && search.trim() !== '') { qs.push("search=" + search); }
    try {
			const items = await CustomerService.retrieve(qs.join(';'))
			setCustomers(items)
			setLoaded(true)
		} catch (error) {

		}
  }

	const actions: DropdownButtonSectionProps[] = [
		{
			key: 'details',
			showDivider: false,
			items: [
				{ key: 'setAsSales', text: 'Jadikan sales ON/OFF', onPress: (customer: ICustomerResourceShort) => {
            setUpdating(true);
            setAsSales(customer);
          }
        },
        { key: 'view', text: 'Lihat detail', onPress: (customer: ICustomerResourceShort) => { navigate(`/customers/details/${customer.id}`); } },
			]
		}
	];

  const setAsSales = async (customer: ICustomerResourceShort) => {
    const fd = new FormData
    fd.append('customer_id', customer.id + "");

    if(customer.is_sales){
      fd.append('is_sales', "0")
    }else{
      fd.append('is_sales', "1")
    }
    await CustomerService.setAsSales((customer.id), fd)
    setUpdating(false);
    setLoaded(false);
    init();
    
  }

  return (
    <Container gap={20} className={styles.container}>
      <Container direction='row' gap={10} style={{ zIndex: 1 }}>
			<Input
				placeholder="Cari pelanggan ..."
				endContent={
					<FontAwesomeIcon icon={faSearch} />
				}
				style={{ zIndex: 1 }}
				value={search}
				onValueChange={(value) => {
					setSearch(value);
				}}
			/>
		</Container>
      <Table  aria-label="Daftar area" isStriped>
        <TableHeader>
          <TableColumn>No</TableColumn>
          <TableColumn>Nama</TableColumn>
          <TableColumn>No Hp</TableColumn>
          <TableColumn>Email</TableColumn>
          <TableColumn>Wallet</TableColumn>
          <TableColumn>Quota Search</TableColumn>
          <TableColumn>Status Sales</TableColumn>
          <TableColumn width={40}>Action</TableColumn>
        </TableHeader>
        <TableBody isLoading={!loaded} loadingContent={<Spinner />} emptyContent={<Text>Belum ada customer terdaftar</Text>}>
          {customers.map((customer, index) => {
            return <TableRow key={customer.id}>
              <TableCell>
                <Text size='base' weight='medium'>
                  {index+1}
                </Text>
              </TableCell>
              <TableCell>
                <Text size='base' weight='medium'>
                  {customer.first_name} {customer.last_name || ""} 
                </Text>
              </TableCell>
              <TableCell>
                <Text size='base' weight='medium'>
                  {customer.phone_with_code} 
                </Text>
              </TableCell>
              <TableCell>
                <Text size='base' weight='medium'>
                  {customer.email}
                </Text>
              </TableCell>
              <TableCell>
                <Text size='base' weight='medium'>
                  {"Rp. "+ NumberService.getNumberWithSeparator(customer.wallet)}
                </Text>
              </TableCell>
              <TableCell>
                <Text size='base' weight='medium'>
                  {customer.quota_search_used} / {customer.quota_search}
                </Text>
              </TableCell>
              <TableCell>
                <Text size='base' weight='medium'>
                  {customer.is_sales ? <Chip color={'primary'}>Sales</Chip> : <Chip color={'danger'}>User</Chip>}
                </Text>
              </TableCell>
              <TableCell key={'action'}>
                <DropdownButton icon={faChevronDown} sections={actions} data={customer} />
              </TableCell>
            </TableRow>;
          })}
        </TableBody>
      </Table>
      <Modal isOpen={updating}>
        <ModalContent>
          <ModalBody>
            <Spinner>Harap tunggu ...</Spinner>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default ListCustomer;