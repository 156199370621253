import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import styles from './styles.module.scss'
import PortalPage from './portal';
import LoginPage from './auth/login';
//import CustomersPage from './home/customers';

type MainPageProps = {
  children?: React.ReactNode;
};

const MainPage: React.FC<MainPageProps> = (props) => {
  return (
    <section className={styles.container}>
      <section className={styles.content}>
        <BrowserRouter>
          <Routes>
            <Route path='/*' element={<PortalPage />} />
            <Route path='/login' element={<LoginPage />} />
          </Routes>
        </BrowserRouter>
      </section>
    </section>
  )
};

export default MainPage;