import React, { useEffect } from 'react';
import Text from '../../text';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { Spinner, Divider, Chip } from "@nextui-org/react";
import DropdownButton, { DropdownButtonSectionProps } from '../../buttons/dropdown';
import Container from '../../container';
import styles from './styles.module.scss';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import GlobalConfig from '../../../config';
import ZoneService from '../../../services/zones/zone';
import { IZoneResourceShortProps } from '../../../props/zones/zone';
import InactivateZone from '../modals/inactivate';
import ActivateZone from '../modals/activate';
import ZonePriceList from '../prices/list';


type ZoneDetailsProps = {
    zoneId: number;
};

const ZoneDetails: React.FC<ZoneDetailsProps> = (props) => {
    const navigate = useNavigate();

    const mapStatus = useJsApiLoader(GlobalConfig.googleMapConfig as any);

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [zone, setZone] = React.useState<IZoneResourceShortProps | undefined>();
    const [activeModal, setActiveModal] = React.useState<string>("");

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _zone = (await ZoneService.read(props.zoneId)).result;
        setZone(_zone);
        setLoaded(true);
    }

    const getActions = (): DropdownButtonSectionProps[] => {
        const actions: DropdownButtonSectionProps[] = [
            {
                key: 'details',
                showDivider: true,
                items: [
                    { key: 'update', text: 'Ubah detail', onPress: (_zone: IZoneResourceShortProps) => { navigate(`/zones/${_zone.id}/update`); } }
                ]
            }
        ];

        if (zone && zone.is_active) {
            actions.push({
                key: 'deletion',
                items: [
                    { key: 'inactivate', text: 'Non-aktifkan zona', variant: 'danger', onPress: () => { setActiveModal('inactivate') } }
                ]
            });
        } else {
            actions.push({
                key: 'activation',
                items: [
                    { key: 'activate', text: 'Aktifkan zona', variant: 'success', onPress: () => { setActiveModal('activate') } }
                ]
            });
        }

        return actions;
    }

    const _onCloseModal = (refresh?: boolean) => {
        setActiveModal("");

        if (refresh) {
            setLoaded(false);
            init();
        }
    }

    return <Container className={styles.container} gap={20} style={{
            backgroundColor: 'white',
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 20,
            // alignItems: 'center',
            // justifyContent: 'center',
            // height: '80vh',
        }}>
        {!loaded ? <Spinner label='Memuat detail ...' /> : null}
        {loaded && zone ? <>
            <Container direction='row' justifyContent='space-between' alignItems='center'>
                <Text size={'xl'} weight='bold'>{zone.name}</Text>
                <DropdownButton data={zone} sections={getActions()} backdrop='blur' icon={faChevronDown} />
            </Container>
            <Container gap={20}>
                <Container gap={5}>
                    <Text size={'small'} weight={'medium'}>Status</Text>
                    {!zone.is_active ? <Chip color='danger'>Tidak Aktif</Chip> : null}
                    {zone.is_active ? <Chip color='success'>Aktif</Chip> : null}
                </Container>
                <Container gap={5}>
                    <Text size={'small'} weight={'medium'}>Titik Tengah Zona</Text>
                    {mapStatus.isLoaded ? <GoogleMap mapContainerStyle={{ height: 200, maxWidth: 720, borderRadius: 10, border: '1px solid #c8c6c4' }}
                        zoom={15}
                        center={{ lat: Number(zone.latitude), lng: Number(zone.longitude) }} >
                        <MarkerF position={{ lat: Number(zone.latitude), lng: Number(zone.longitude) }} />
                    </GoogleMap> : null}
                </Container>
                <Container>
                    <Text size={'small'} weight={'medium'}>Latitude, Longitude</Text>
                    <Text>{zone.latitude}, {zone.longitude}</Text>
                </Container>
                <Container>
                    <Text size={'small'} weight={'medium'}>Radius</Text>
                    <Text>{zone.radius} km</Text>
                </Container>
                <Divider />
            </Container>
            <ZonePriceList zoneId={zone.id} />
        </> : null}
        {activeModal === 'inactivate' && zone ? <InactivateZone zone={zone} onClose={_onCloseModal} /> : null}
        {activeModal === 'activate' && zone ? <ActivateZone zone={zone} onClose={_onCloseModal} /> : null}
    </Container>
};

export default ZoneDetails;