import React, { CSSProperties, FC, ReactNode } from 'react';

type AlignItems =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'baseline'
  | 'stretch';

type JustifyContent =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'start'
  | 'end'
  | 'left'
  | 'right';

interface ContainerProps {
  direction?: 'column' | 'row';
  gap?: number;
  wrap?: boolean;
  grow?: number;
  justifyContent?: JustifyContent;
  alignItems?: AlignItems;
  children?: ReactNode;
  className?: string;
  onClick?(ev?: any): void;
  style?: CSSProperties;
}

const Container: FC<ContainerProps> = ({ direction = 'column', gap = 0, justifyContent = 'unset', alignItems = 'unset', children, grow, className, wrap, onClick, style }) => {
  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: direction,
    gap: `${gap}px`,
    alignItems: alignItems,
    justifyContent: justifyContent,
    flexWrap: wrap ? 'wrap' : undefined,
    flexGrow: grow,
    ...style,
  };

  return <div className={className} style={containerStyle} onClick={onClick}>{children}</div>;
};

export default Container;
