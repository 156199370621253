import React from 'react';
import AreaForm from '../../../components/areas/form';
import { Routes, Route, useParams } from 'react-router-dom';
import Container from '../../../components/container';
import Text from '../../../components/text';
import styles from './styles.module.scss';
import AreaDetails from '../../../components/areas/details';

type AreaDetailsPageProps = {
    children?: React.ReactNode;
};

const AreaDetailsPage: React.FC<AreaDetailsPageProps> = (props) => {
    const { areaId } = useParams();

    return <Container className={styles.container} gap={20}>
      <AreaDetails areaId={Number(areaId)} />
    </Container>;
};

export default AreaDetailsPage;