export type WithdrawMethodProps = {
    key: string;
    text: string;
    method: string;
}

const WithdrawMethods: WithdrawMethodProps[] = [
    {key: "transfer", text: "Transfer", method: "transfer"},
    {key: "cash", text: "Cash", method: "cash"}
];

export default WithdrawMethods;