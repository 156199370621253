import React from 'react';
import AreaForm from '../../../components/areas/form';
import { Routes, Route, useParams } from 'react-router-dom';
import Container from '../../../components/container';
import Text from '../../../components/text';
import styles from './styles.module.scss';
import AreaDetails from '../../../components/areas/details';
import DriverDetails from '../../../components/drivers/details';

type DriverDetailsPageProps = {
  children?: React.ReactNode;
};

const DriverDetailsPage: React.FC<DriverDetailsPageProps> = (props) => {
  const { driverId } = useParams();

  return <Container className={styles.container} gap={20}>
    <DriverDetails driverId={driverId as string} />
  </Container>;
};

export default DriverDetailsPage;