import React from 'react';
import Container from '../../../components/container';
import Text from '../../../components/text';
import CustomerForm from '../../../components/customers/form';
import styles from './styles.module.scss'
import { Button } from '@nextui-org/react';

type TypeFormCustomerPageProps = {
  children?: React.ReactNode;
};

const FormCustomerPage: React.FC<TypeFormCustomerPageProps> = (props) => {
  return (
    <Container className={styles.container} gap={20} style={{ height: 'calc(100vh - 60px)', position: 'relative' }}>
      <Text size='xl' weight='bold'>Ubah Detail Pelanggan</Text>
      <CustomerForm />
      <Text size='xs' weight='normal'>Catatan: Password akan dikirimkan melalui email yang didaftarkan</Text>
      <Container
        gap={10}
        direction='row'
        style={{ bottom: 0, width: '100%', padding: 20, background: 'white', position: "absolute", left: 0 }}>
        <Button size='sm' color='primary' style={{ flex: 1 }}>Simpan Perubahan</Button>
        <Button size='sm'>Batal</Button>
      </Container>
    </Container>
  )
};

export default FormCustomerPage;