import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react';
import React, { useState } from 'react';
import Text from '../../text';
import Container from '../../container';
import { useNavigate } from 'react-router-dom';
import avatar from '../../../assets/avatar/avatar_1.png'
import { IDriverResourceShort } from '../../../props/drivers';
import GlobalConfig from '../../../config';
import DriverService from '../../../services/drivers';

type UpdateDataModalAreaProps = {
  children?: React.ReactNode;
  onClose(refresh?: boolean): void;
  driver: IDriverResourceShort
};

const UpdateDataModalArea: React.FC<UpdateDataModalAreaProps> = (props) => {
  const { driver } = props
  const navigate = useNavigate();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [phone, setPhone] = useState<string>('')
  const [email, setEmail] = useState<string>('')

  const _onSubmit = async () => {
    const fd = new FormData

    if(email !== ''){
      console.log("email")
      fd.append('email', email)
    }

    if(phone !== ''){
      console.log("phone")
      fd.append('phone_number', '0'+phone)
      fd.append('phone_with_code', '+62'+phone)
    }
    
    setSubmitting(true)
    try {
      await DriverService.updatePassword(String(driver.id), fd)
      props.onClose(true)
    } catch (error) {
      setSubmitting(false)

    }
  }

  return (
    <Modal size='sm' isOpen={true} onOpenChange={(isOpen) => { if (!isOpen) { props.onClose() } }}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">Edit Data Driver</ModalHeader>
        <ModalBody>
          <Container gap={20}>
            <Text size='xs'>Edit driver berikut dengan data baru</Text>
            <Container direction='row' alignItems='center' gap={10}>
              <img
                src={GlobalConfig.assetHostname + '/storage/' + driver.profile_picture}
                alt="profile"
                style={{ height: 40, width: 40, borderRadius: 100 }} />
              <Container>
                <Text size={'small'} weight={'medium'}>{driver.first_name}</Text>
                <Text>{Number(driver.gender) === 1 ? 'Laki-laki' : 'Perempuan'}</Text>
              </Container>
            </Container>
            <Container gap={20}>
              <Container>
                <Text size={'small'} weight={'medium'}>Alamat Email</Text>
                <Text>{driver.email}</Text>
              </Container>
              <Container>
                <Text size={'small'} weight={'medium'}>Nomor Telepon</Text>
                <Text>{driver.phone_with_code}</Text>
              </Container>
            </Container>
            <Input
              isRequired={false}
              isReadOnly={submitting}
              value={email}
              type="email"
              label="Email Baru"
              placeholder=" "
              labelPlacement="outside"
              onValueChange={(value) => {
                setEmail(value);
              }}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label style={{ marginRight: '8px' }}>+62</label>
              <Input
                isRequired={false}
                isReadOnly={submitting}
                value={phone}
                type="text"
                placeholder="812999922"
                onValueChange={(value) => {
                  setPhone(value);
                }}
              />
            </div>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onPress={_onSubmit} isLoading={submitting} style={{ width: '70%' }}>
            Edit Driver
          </Button>
          <Button isDisabled={submitting} onPress={() => props.onClose()} style={{ width: '30%' }}>
            Batal
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
};

export default UpdateDataModalArea;