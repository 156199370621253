import { IDriverResourceShort, IVehicleResourceShortProps } from '../../props/drivers';
import { IDriverWalletHistoryResourceShortProps } from '../../props/drivers/walletHistory';
import { IDriverWalletHistoryNewResourceShortProps } from '../../props/drivers/walletHistoryNew';

import Request from './../general/request';

const DriverService = {
  retrieve: async (qs?: string) => {
    try {
      const response = await Request.get<IDriverResourceShort[]>(`/api/admin/drivers?${qs || ""}`);
      return response.result;
    } catch (error) {
      throw error
    }
  },
  read: async (driverId: string) => {
    try {
      const response = await Request.get<IDriverResourceShort>(`/api/admin/drivers/${driverId}`);
      return response;
    } catch (error) {
      throw error
    }
  },
  create: async (data: FormData) => {
    try {
      const response = await Request.post<IDriverResourceShort>('/api/admin/drivers', data);
      return response;
    } catch (error) {
      throw error
    }
  },
  update: async (driverId: string, data: FormData) => {
    try {
      const response = await Request.put<IDriverResourceShort>(`/api/admin/drivers/${driverId}`, data);
      return response;
    } catch (error) {
      throw error
    }
  },
  updatePassword: async (driverId: string, data: FormData) => {
    try {
      const response = await Request.post<IDriverResourceShort>(`/api/admin/drivers/${driverId}/update_password`, data);
      return response;
    } catch (error) {
      throw error
    }
  },
  updateStatus: async (driverId: number, data: FormData) => {
    try {
      const response = await Request.post<IDriverResourceShort>(`/api/admin/drivers/${driverId}/update_status`, data);
      return response;
    } catch (error) {
      throw error
    }
  },
  accept: async (driverId: string) => {
    try {
      const response = await Request.get<IDriverResourceShort>(`/api/admin/drivers/${driverId}/accept_document`);
      return response;
    } catch (error) {
      throw error
    }
  },
  vehicle: {
    retrieve: async (driverId: string) => {
      try {
        const response = await Request.get<IVehicleResourceShortProps[]>(`/api/admin/drivers/${driverId}/vehicles`);
        return response;
      } catch (error) {
        throw error
      }
    },
    get: async (driverId: string, id: string) => {
      try {
        const response = await Request.get<IVehicleResourceShortProps>(`/api/admin/drivers/${driverId}/vehicles/${id}`);
        return response.result;
      } catch (error) {
        throw error
      }
    },
    update_status_vehicle: async (id: number) => {
      try {
        const response = await Request.get<IVehicleResourceShortProps>(`/api/admin/vehicles/${id}/update_status_vehicle`);
        return response.result;
      } catch (error) {
        throw error
      }
    },
    accept: async (id: string) => {
      try {
        const response = await Request.get<IVehicleResourceShortProps>(`/api/admin/vehicles/${id}/accept_document`);
        return response.result;
      } catch (error) {
        throw error
      }
    },
    reject: async (id: string) => {
      try {
        const response = await Request.get<IVehicleResourceShortProps>(`/api/admin/vehicles/${id}/reject_document`);
        return response.result;
      } catch (error) {
        throw error
      }
    },
  },
  wallet: {
    withdraw: {
      request: async (data: FormData) => {
        try {
          const response = await Request.post<IDriverWalletHistoryResourceShortProps>(`/api/admin/drivers/wallets/withdraw`, data);
          return response;
        } catch (error) {
          throw error
        }
      },
    },
    topup: {
      request: async (data: FormData) => {
        try {
          const response = await Request.post<IDriverWalletHistoryResourceShortProps>(`/api/admin/drivers/wallets/topup`, data);
          return response;
        } catch (error) {
          throw error
        }
      },
      read: async (walletHistoryId: string) => {
        try {
          const response = await Request.get<IDriverWalletHistoryResourceShortProps>(`/api/admin/drivers/wallets/${walletHistoryId}`);
          return response;
        } catch (error) {
          throw error
        }
      }
    },
    history: {
      analytics: {
        getTotalAmount: (histories: IDriverWalletHistoryResourceShortProps[]) => {
          let topup = 0;
          let cash = 0;
          let ewallet = 0;
          let qrcode = 0;
          let absensi = 0;

          histories.forEach((history) => {
            topup += Number(history.amount);
            if (history.payment_method === 'ewallet') {
              ewallet += Number(history.amount);
            } else if (history.payment_method === 'qrcode') {
              qrcode += Number(history.amount);
            } else {
              cash += Number(history.amount);
            }
            if (history.history_type === 'absensi') {
              absensi += Number(history.amount);
            }
          });

          return { topup, ewallet, cash, qrcode, absensi };
        }
      },
      retrieve: async (qs?: string) => {
        try {
          const response = await Request.get<IDriverWalletHistoryResourceShortProps[]>(`/api/admin/drivers/wallets/histories?${qs || ""}`);
          return response;
        } catch (error) {
          throw error
        }
      },
      retrieveNew: async (qs?: string) => {
        try {
          const response = await Request.get<IDriverWalletHistoryNewResourceShortProps>(`/api/admin/drivers/wallet_histories?${qs || ""}`);
          return response;
        } catch (error) {
          throw error
        }
      },
      absensi: async (qs?: string) => {
        try {
          const response = await Request.get<IDriverWalletHistoryResourceShortProps[]>(`/api/admin/drivers/wallets/absensi?${qs || ""}`);
          return response;
        } catch (error) {
          throw error
        }
      },
      list: async (qs?: string) => {
        try {
          const response = await Request.get<IDriverWalletHistoryResourceShortProps[]>(`/api/admin/list/wallet/histories?${qs || ""}`);
          return response;
        } catch (error) {
          throw error
        }
      },
    }
  }
}

export default DriverService;