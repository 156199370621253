import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react';
import React from 'react';
import Text from '../../../text';
import Container from '../../../container';
import { IStationAreaResourceShortProps } from '../../../../props/stations/area';
import { useJsApiLoader } from '@react-google-maps/api';
import GlobalConfig from '../../../../config';
import { IStationAreaPriceResourceShortProps } from '../../../../props/stations/price';
import NumberService from '../../../../services/general/number';
import StationAreaPriceService from '../../../../services/station/price';

type DeleteAreaPriceProps = {
    children?: React.ReactNode;
    area: IStationAreaResourceShortProps;
    price: IStationAreaPriceResourceShortProps;
    onClose(refresh?: boolean): void;
};

const DeleteAreaPrice: React.FC<DeleteAreaPriceProps> = (props) => {
    const { area, price } = props;
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    const mapStatus = useJsApiLoader(GlobalConfig.googleMapConfig as any);

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            await StationAreaPriceService.delete(area.id, price.id);
            props.onClose(true);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return (
        <Modal size='sm' isOpen={true} onOpenChange={(isOpen) => { if (!isOpen) { props.onClose() } }}>
            <ModalContent>
                <ModalHeader className="flex flex-col gap-1">Hapus Harga</ModalHeader>
                <ModalBody>
                    <Text size='xs'>Apakah anda yakin untuk menghapus harga dibawah ini?</Text>
                    <Container>
                        <Text size={'small'} weight={'medium'}>Nama Area</Text>
                        <Text>{area.name}</Text>
                    </Container>
                    <Container>
                        <Text size={'small'} weight={'medium'}>Jarak</Text>
                        {price.max_km > 0 ? <Text>{Number(price.min_km)}-{Number(price.max_km)} km</Text> : null}
                        {price.max_km <= 0 ? <Text>{`>`} {Number(price.min_km)} km</Text> : null}
                    </Container>
                    <Container>
                        <Text size={'small'} weight={'medium'}>Harga minimum</Text>
                        <Text>Rp. {NumberService.getNumberWithSeparator(Number(price.min_price))}</Text>
                    </Container>
                    <Container>
                        <Text size={'small'} weight={'medium'}>Harga per km</Text>
                        <Text>Rp. {NumberService.getNumberWithSeparator(Number(price.price_per_km))} / km</Text>
                    </Container>
                    <Container>
                        <Text size={'small'} weight={'medium'}>Surcharge</Text>
                        <Text>Rp. {NumberService.getNumberWithSeparator(Number(price.surcharge))}</Text>
                    </Container>
                    <Container>
                        <Text size={'small'} weight={'medium'}>Persentase potongan</Text>
                        <Text>{NumberService.getNumberWithSeparator(Number(price.percentage_fee))}%</Text>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onPress={_onSubmit} isLoading={submitting} style={{ width: '30%' }}>
                        Hapus
                    </Button>
                    <Button isDisabled={submitting} onPress={() => props.onClose()} style={{ width: '70%' }}>
                        Batal
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
};

export default DeleteAreaPrice;