import React from 'react';
import Container from '../container';
import Text from './../text';

import styles from './styles.module.scss';

type FooterProps = {
    text: string;
    color?: 'red';
};

const Banner: React.FC<FooterProps> = (props) => {
    return <Container className={`${styles.container} ${props.color ? styles[props.color] : ''}`}>
        <Text size="small">{props.text}</Text>
    </Container>
};

export default Banner;