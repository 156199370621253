import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react';
import React, { useState } from 'react';
import Text from '../../text';
import Container from '../../container';
import { useNavigate } from 'react-router-dom';
import avatar from '../../../assets/avatar/avatar_1.png'
import { IDriverResourceShort } from '../../../props/drivers';
import GlobalConfig from '../../../config';
import DriverService from '../../../services/drivers';

type UpdatePasswordModalAreaProps = {
  children?: React.ReactNode;
  onClose(refresh?: boolean): void;
  driver: IDriverResourceShort
};

const UpdatePasswordModalArea: React.FC<UpdatePasswordModalAreaProps> = (props) => {
  const { driver } = props
  const navigate = useNavigate();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [password, setPassword] = useState<string>('')

  const _onSubmit = async () => {
    const fd = new FormData
    fd.append('password', password)
    setSubmitting(true)
    try {
      await DriverService.updatePassword(String(driver.id), fd)
      props.onClose(true)
    } catch (error) {
      setSubmitting(false)

    }
  }

  return (
    <Modal size='sm' isOpen={true} onOpenChange={(isOpen) => { if (!isOpen) { props.onClose() } }}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">Ganti Password</ModalHeader>
        <ModalBody>
          <Container gap={20}>
            <Text size='xs'>Ganti password driver berikut dengan password baru.</Text>
            <Container direction='row' alignItems='center' gap={10}>
              <img
                src={GlobalConfig.assetHostname + '/storage/' + driver.profile_picture}
                alt="profile"
                style={{ height: 40, width: 40, borderRadius: 100 }} />
              <Container>
                <Text size={'small'} weight={'medium'}>{driver.first_name}</Text>
                <Text>{Number(driver.gender) === 1 ? 'Laki-laki' : 'Perempuan'}</Text>
              </Container>
            </Container>
            <Container gap={20}>
              <Container>
                <Text size={'small'} weight={'medium'}>Alamat Email</Text>
                <Text>{driver.email}</Text>
              </Container>
              <Container>
                <Text size={'small'} weight={'medium'}>Nomor Telepon</Text>
                <Text>{driver.phone_with_code}</Text>
              </Container>
            </Container>
            <Input
              isRequired={true}
              isReadOnly={submitting}
              value={password}
              type="password"
              label="Password Baru"
              placeholder=" "
              labelPlacement="outside"
              onValueChange={(value) => {
                setPassword(value);
              }}
            />
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onPress={_onSubmit} isLoading={submitting} style={{ width: '70%' }}>
            Ganti Password
          </Button>
          <Button isDisabled={submitting} onPress={() => props.onClose()} style={{ width: '30%' }}>
            Batal
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
};

export default UpdatePasswordModalArea;