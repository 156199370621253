import { IUserResourceShort } from '../../props/admin/customer';
import { ICustomerResourceShort } from '../../props/customers';
import Request from './../general/request';

const AdminService = {
  retrieve: async (qs?: string) => {
    try {
      const response = await Request.get<IUserResourceShort[]>(`/api/admins?${qs || ""}`);
      return response.result;
    } catch (error) {
      throw error
    }
  },
  get: async (id: string) => {
    try {
      const response = await Request.get<IUserResourceShort>(`/api/admins/${id}`);
      return response.result;
    } catch (error) {
      throw error
    }
  },
  create: async (data: FormData) => {
    try {
      const response = await Request.post<IUserResourceShort[]>(`/api/admins`, data);
      return response.result;
    } catch (error) {
      throw error
    }
  },
  update: async (data: FormData, id: string) => {
    try {
      const response = await Request.post<IUserResourceShort[]>(`/api/admins/${id}`, data);
      return response.result;
    } catch (error) {
      throw error
    }
  },
}

export default AdminService;