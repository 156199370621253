import React, { useEffect } from 'react';
import Container from '../container';
import Text from '../text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import avatar from './../../assets/avatar/avatar_1.png'
import { Spinner, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownSection, DropdownTrigger } from '@nextui-org/react';
import DisableCustomerModal from './modal/disableCusomer';
import { useNavigate } from 'react-router-dom';
import { ICustomerResourceShort } from '../../props/customers';
import UpdatePasswordModalArea from './modal/changePassword';
import UpdateQuotaModal from './modal/changeQuota';
import CustomerService from '../../services/customers';
import GeneralFileService from '../../services/general/file';


type TypeCustomerDetailProps = {
  customerId: string;
};

const CustomerDetail: React.FC<TypeCustomerDetailProps> = (props) => {
  const navigate = useNavigate();

  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [customer, setCustomers] = React.useState<ICustomerResourceShort | undefined>();
  const [activeModal, setActiveModal] = React.useState<'updatePassword' | undefined>();
  const [activeModalQuota, setActiveModalQuota] = React.useState<'updateQuota' | undefined>();
  

  useEffect(() => {
    getCustomer()
    return () => {

    }
  }, [])

  const getCustomer = async () => {
    try {
      const _customers = (await CustomerService.read(props.customerId)).result;
      setCustomers(_customers)
      setLoaded(true)
    } catch (error) {

    }
  }

  const statusCustomer = async () => {
    const fd = new FormData
    if(customer){
      if(customer.status === 1){
        fd.append('status', '0')
      }else{
        fd.append('status', '1')
      }
      setLoaded(false)
      try {
        await CustomerService.updateStatus(String(customer.id), fd)
        getCustomer()
        setLoaded(true)
      } catch (error) {
        setLoaded(true)
  
      }
    }
  }

  return (
    <Container gap={20} style={{
        backgroundColor: 'white',
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 20,
        // alignItems: 'center',
        // justifyContent: 'center',
        // height: '100vh',
      }}>
      {/* <DisableCustomerModal /> */}
      {!loaded ? <Spinner label='Memuat detail ...' /> : null}
      {loaded && customer ? <>
      <Container direction='row' alignItems='center' justifyContent='space-between'>
        <Container direction='row' alignItems='center' gap={10}>
          <img src={GeneralFileService.getFileUrl(customer.profile_picture)} alt="profile" style={{ height: 40, width: 40, borderRadius: 100 }} />
          <Container>
            <Text size='sm' weight='normal'>{customer.first_name} {customer.last_name}</Text>
            <Text size='xs'>{Number(customer.gender) === 1 ? 'Laki-laki' : 'Perempuan'}</Text>
          </Container>
        </Container>
        <Dropdown backdrop="blur">
          <DropdownTrigger>
            <FontAwesomeIcon icon={faChevronDown} size='sm' />
          </DropdownTrigger>

          <DropdownMenu aria-label="Static Actions">
            <DropdownSection showDivider>
              {/* <DropdownItem key="copy" onPress={() => navigate(`/customers/${customer?.id}/topup/request`)}>Ubah detail</DropdownItem> */}
              <DropdownItem key="edit" onPress={() => setActiveModal('updatePassword')}>Reset password</DropdownItem>
            </DropdownSection>
            <DropdownSection showDivider>
              {/* <DropdownItem key="copy" onPress={() => navigate(`/customers/${customer?.id}/topup/request`)}>Ubah detail</DropdownItem> */}
              <DropdownItem key="editquota" onPress={() => setActiveModalQuota('updateQuota')}>Update Quota</DropdownItem>
            </DropdownSection>
            <DropdownSection>
              <DropdownItem key="delete" className="text-danger" color="danger" onPress={statusCustomer}>
                Non-aktifkan pelanggan
              </DropdownItem>
            </DropdownSection>
          </DropdownMenu>
        </Dropdown>
      </Container>
      <Container gap={10}>
        <Container>
          <Text size='xs'>Alamat Email</Text>
          <Text size='sm' weight='medium'>{customer.email}</Text>
        </Container>
        <Container>
          <Text size='xs'>Nomor Telepon</Text>
          <Text size='sm' weight='medium'>{customer.phone_with_code}</Text>
        </Container>
        {/* <Container>
          <Text size='xs'>Tanggal Bergabung</Text>
          <Text size='sm' weight='medium'>{customer.first_name}</Text>
        </Container> */}
        <Container>
          <Text size='xs'>Status</Text>
          <Text size='sm' weight='medium'>{Number(customer.status) === 1 ? 'Aktif' : 'Tidak Aktif'}</Text>
        </Container>
        <Divider />
        <Container>
          <Text size='xs'>Quota Search</Text>
          <Text size='sm' weight='medium'>{customer.quota_search}</Text>
        </Container>
        {/* <Container>
          <Text size='xs'>Alamat</Text>
          <Text size='sm' weight='medium'>Jl. Wahid Hayim 1, Jakarta Pusat</Text>
        </Container>
        <Container>
          <Text size='xs'>Tanggal Lahir</Text>
          <Text size='sm' weight='medium'>01/01/1990</Text>
        </Container> */}
      </Container>
      </> : null}
      {activeModal && activeModal === 'updatePassword' && customer && <UpdatePasswordModalArea
      onClose={function (refresh?: boolean | undefined): void {
        setActiveModal(undefined)
      }}
      customer={customer} />}
      {activeModalQuota && activeModalQuota === 'updateQuota' && customer && <UpdateQuotaModal
      onClose={function (refresh?: boolean | undefined): void {
        setActiveModalQuota(undefined)
      }}
      customer={customer} />}
    </Container>
  );
};

export default CustomerDetail;