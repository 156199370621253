export type TopUpMethodProps = {
    key: string;
    text: string;
    method: string;
    surchargePercentage: number;
    surchargeAmount: number;
}

const TopUpMethods: TopUpMethodProps[] = [
    {key: "transfer", text: "Transfer", method: "transfer", surchargePercentage: 0, surchargeAmount: 0},
    {key: "cash", text: "Cash", method: "cash", surchargePercentage: 1, surchargeAmount: 0},
    {key: "QR_CODE", text: "QR Code", method: "qrcode", surchargePercentage: 0.7, surchargeAmount: 0}
];

export default TopUpMethods;