import React, { useEffect, useState } from 'react';
import Text from '../../../components/text';
import styles from './styles.module.scss'
import Container from './../../../components/container';
import ListOrder from '../../../components/orders/list';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select, SelectItem, Selection, Spinner, Card, CardBody, CardFooter, CardHeader, Divider } from '@nextui-org/react';
import TripService from '../../../services/trips';
import { ITripResourceShortProps, ITripHistoriesResourceShortProps } from '../../../props/trips';
import NumberService from '../../../services/general/number';
import { IDriverWalletHistoryResourceShortProps } from '../../../props/drivers/walletHistory';
import { IDriverWalletHistoryNewResourceShortProps } from '../../../props/drivers/walletHistoryNew';

import DriverService from '../../../services/drivers';

type AnalyticDashboardPageProps = {
  children?: React.ReactNode;
};

const AnalyticDashboardPage: React.FC<AnalyticDashboardPageProps> = (props) => {
  const [duration, setDuration] = useState("today");
  const [trips, setTrips] = React.useState<ITripResourceShortProps[]>([]);
  const [driverWalletHistories, setDriverWalletHistories] = React.useState<IDriverWalletHistoryResourceShortProps[]>([]);
  const [driverAbsensiHistories, setDriverAbsensiHistories] = React.useState<IDriverWalletHistoryResourceShortProps[]>([]);
  const [loaded, setLoaded] = React.useState<boolean>(true);

  const [driverWalletHistoriesNew, setDriverWalletHistoriesNew] = React.useState<IDriverWalletHistoryNewResourceShortProps>({total_topup:0, total_ewallet:0, total_cash:0, total_qrcode:0, total_absensi:0});
  const [tripsHistories, setTripsHistories] = React.useState<ITripHistoriesResourceShortProps>({jumlah_ritase:0, ritase_selesai:0, ritase_diproses:0, ritase_dibatalkan:0, incomes:0, applicationFee:0, adminFee:0});

  useEffect(() => {
    _onSearch("today");
  }, []);

  const _onDurationChange = (value: any) => {
    setDuration(value);
    _onSearch(value.currentKey);
  }

  const jakartaTimeZone = 'Asia/Jakarta';

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const _onSearch = async (_duration: string) => {
    setLoaded(false);

    const currentDateTime = new Date(new Date().toLocaleString('en-US', { timeZone: jakartaTimeZone }));
    let startDate = new Date(currentDateTime);
    startDate.setHours(0, 0, 1, 0);
    startDate = new Date(formatDate(startDate));

    let endDate = new Date(currentDateTime);
    endDate.setHours(23, 59, 59, 0);
    endDate = new Date(formatDate(endDate));

    console.log(startDate)
    console.log(endDate)

    if (_duration === 'yesterday') {
      startDate = new Date(currentDateTime);
      startDate.setHours(0, 0, 1, 0);
      startDate.setDate(currentDateTime.getDate() - 1);
      startDate = new Date(formatDate(startDate));

      endDate = new Date(currentDateTime);
      endDate.setHours(23, 59, 59, 0);
      endDate.setDate(currentDateTime.getDate() - 1);
      endDate = new Date(formatDate(endDate));
    } else if (_duration === 'thisWeek') {
      startDate = new Date(currentDateTime);
      startDate.setHours(0, 0, 1, 0);
      startDate.setDate(currentDateTime.getDate() - currentDateTime.getDay());
      startDate = new Date(formatDate(startDate));

      endDate = new Date(currentDateTime);
      endDate.setHours(23, 59, 59, 0);
      endDate.setDate(currentDateTime.getDate() + (6 - currentDateTime.getDay()));
      endDate = new Date(formatDate(endDate));
    } else if (_duration === 'lastWeek') {
      startDate = new Date(currentDateTime);
      startDate.setHours(0, 0, 1, 0);
      startDate.setDate(currentDateTime.getDate() - currentDateTime.getDay() - 7);
      startDate = new Date(formatDate(startDate));

      endDate = new Date(currentDateTime);
      endDate.setHours(23, 59, 59, 0);
      endDate.setDate(currentDateTime.getDate() - currentDateTime.getDay() - 1);
      endDate = new Date(formatDate(endDate));
    } else if (_duration === 'thisMonth') {
      startDate = new Date(currentDateTime);
      startDate.setHours(0, 0, 1, 0);
      startDate.setDate(1);
      startDate = new Date(formatDate(startDate));

      endDate = new Date(currentDateTime);
      endDate.setHours(23, 59, 59, 0);
      endDate.setMonth(currentDateTime.getMonth() + 1, 0);
      endDate = new Date(formatDate(endDate));
    } else if (_duration === 'lastMonth') {
      startDate = new Date(currentDateTime);
      startDate.setHours(0, 0, 1, 0);
      startDate.setDate(1);
      startDate.setMonth(currentDateTime.getMonth() - 1);
      startDate = new Date(formatDate(startDate));

      endDate = new Date(currentDateTime);
      endDate.setHours(23, 59, 59, 0);
      endDate.setDate(0);
      endDate = new Date(formatDate(endDate));
    }

    const qs = [
      `start_time=${formatDate(startDate)}`,
      `end_time=${formatDate(endDate)}`
    ];

    // const _trips = (await TripService.retrieve(qs.join("&"))).result;
    // const _driverWalletHistories = (await DriverService.wallet.history.retrieve(qs.join("&"))).result;
    // const _driverWalletAbsensi = (await DriverService.wallet.history.absensi(qs.join("&"))).result;

    const _driverWalletHistoriesNew = (await DriverService.wallet.history.retrieveNew(qs.join("&"))).result;
    const _tripsNew = (await TripService.retrieveNew(qs.join("&"))).result;

    // setTrips(_trips);
    // setDriverWalletHistories(_driverWalletHistories);
    // setDriverAbsensiHistories(_driverWalletAbsensi);

    setDriverWalletHistoriesNew(_driverWalletHistoriesNew);
    setTripsHistories(_tripsNew);
    setLoaded(true);
  }

  const renderTripSummaryCard = () => {
    // const completedTrips = trips.filter((trip) => trip.status === 'completed');
    // const cancelledTrips = trips.filter((trip) => trip.status === 'cancelled');
    // const inProgressTrips = trips.filter((trip) => trip.status === 'picking_up' || trip.status === 'dropping_off');
    // const totalIncomes = TripService.analytics.totalIncomes(completedTrips);
    // const totalAmount = DriverService.wallet.history.analytics.getTotalAmount(driverAbsensiHistories);

    return <Card>
      <CardBody>
        <Container gap={20}>
          <Container gap={5}>
            <Text weight={'semibold'}>Rangkuman Ritase</Text>
            <Text size={'tiny'}>Total biaya hanya dihitung dari ritase/pesanan yang sudah selesai.</Text>
          </Container>
          <Divider />
          <Container gap={20}>
            <Container direction='row' justifyContent='space-between'>
              <Container grow={1}>
                <Text size={'small'}>Jumlah Semua Ritase</Text>
              </Container>
              <Container alignItems={'flex-end'} style={{ minWidth: 75, maxWidth: 75 }}>
                <Text size={'small'}>{tripsHistories.jumlah_ritase}</Text>
              </Container>
            </Container>
            <Container direction='row' justifyContent='space-between'>
              <Container grow={1}>
                <Text size={'small'}>Jumlah Ritase Selesai</Text>
              </Container>
              <Container alignItems={'flex-end'} style={{ minWidth: 75, maxWidth: 75 }}>
                <Text size={'small'}>{tripsHistories.ritase_selesai}</Text>
              </Container>
            </Container>
            <Container direction='row' justifyContent='space-between'>
              <Container grow={1}>
                <Text size={'small'}>Jumlah Ritase Dalam Perjalanan</Text>
              </Container>
              <Container alignItems={'flex-end'} style={{ minWidth: 75, maxWidth: 75 }}>
                <Text size={'small'}>{tripsHistories.ritase_diproses}</Text>
              </Container>
            </Container>
            <Container direction='row' justifyContent='space-between'>
              <Container grow={1}>
                <Text size={'small'}>Jumlah Ritase Dibatalkan</Text>
              </Container>
              <Container alignItems={'flex-end'} style={{ minWidth: 75, maxWidth: 75 }}>
                <Text size={'small'}>{tripsHistories.ritase_dibatalkan}</Text>
              </Container>
            </Container>
          </Container>
          <Divider />
          <Container gap={20}>
            <Container direction='row' justifyContent='space-between'>
              <Container grow={1}>
                <Text size={'small'}>Total Biaya Perjalanan</Text>
              </Container>
              <Container alignItems={'flex-end'} style={{ minWidth: 125, maxWidth: 125 }}>
                <Text size={'small'}>Rp. {NumberService.getNumberWithSeparator(tripsHistories.incomes)}</Text>
              </Container>
            </Container>
            <Container direction='row' justifyContent='space-between'>
              <Container grow={1}>
                <Text size={'small'}>Total Potongan Perjalanan</Text>
              </Container>
              <Container alignItems={'flex-end'} style={{ minWidth: 125, maxWidth: 125 }}>
                <Text size={'small'}>Rp. {NumberService.getNumberWithSeparator(tripsHistories.applicationFee)}</Text>
              </Container>
            </Container>
            <Container direction='row' justifyContent='space-between'>
              <Container grow={1}>
                <Text size={'small'}>Total Biaya Pemeliharaan Aplikasi</Text>
              </Container>
              <Container alignItems={'flex-end'} style={{ minWidth: 125, maxWidth: 125 }}>
                <Text size={'small'}>Rp. {NumberService.getNumberWithSeparator(tripsHistories.adminFee)}</Text>
              </Container>
            </Container>
            <Container direction='row' justifyContent='space-between'>
              <Container grow={1}>
                <Text size={'small'}>Total Absensi</Text>
              </Container>
              <Container alignItems={'flex-end'} style={{ minWidth: 125, maxWidth: 125 }}>
                <Text size={'small'}>Rp. {NumberService.getNumberWithSeparator(driverWalletHistoriesNew.total_absensi)}</Text>
              </Container>
            </Container>
          </Container>
        </Container>
      </CardBody>
    </Card>
  }

  const renderDriverTopUpSummaryCard = () => {
    // const totalAmount = DriverService.wallet.history.analytics.getTotalAmount(driverWalletHistories);

    return <Card>
    <CardBody>
      <Container gap={20}>
        <Container gap={5}>
          <Text weight={'semibold'}>Rangkuman Top-up Driver</Text>
        </Container>
        <Divider />
        <Container gap={20}>
          <Container direction='row' justifyContent='space-between'>
            <Container grow={1}>
              <Text size={'small'}>Total Top-up (Semua Metode Pembayaran)</Text>
            </Container>
            <Container alignItems={'flex-end'} style={{ minWidth: 125, maxWidth: 125 }}>
              <Text size={'small'}>Rp. {NumberService.getNumberWithSeparator(driverWalletHistoriesNew.total_topup)}</Text>
            </Container>
          </Container>
          <Container direction='row' justifyContent='space-between'>
            <Container grow={1}>
              <Text size={'small'}>Total Pembayaran Cash</Text>
            </Container>
            <Container alignItems={'flex-end'} style={{ minWidth: 125, maxWidth: 125 }}>
              <Text size={'small'}>Rp. {NumberService.getNumberWithSeparator(driverWalletHistoriesNew.total_cash)}</Text>
            </Container>
          </Container>
          <Container direction='row' justifyContent='space-between'>
            <Container grow={1}>
              <Text size={'small'}>Jumlah Pembayaran QRIS</Text>
            </Container>
            <Container  alignItems={'flex-end'} style={{ minWidth: 125, maxWidth: 125 }}>
              <Text size={'small'}>Rp. {NumberService.getNumberWithSeparator(driverWalletHistoriesNew.total_qrcode)}</Text>
            </Container>
          </Container>
          <Container direction='row' justifyContent='space-between'>
            <Container grow={1}>
              <Text size={'small'}>Jumlah Pembayaran E-Wallet</Text>
            </Container>
            <Container  alignItems={'flex-end'} style={{ minWidth: 125, maxWidth: 125 }}>
              <Text size={'small'}>Rp. {NumberService.getNumberWithSeparator(driverWalletHistoriesNew.total_ewallet)}</Text>
            </Container>
          </Container>
        </Container>
      </Container>
    </CardBody>
    </Card>
  }

  return <Container gap={20} style={{ padding: 20 }}>
    <Text weight={'semibold'}>Rekam Jejak</Text>
    <Select fullWidth={true} label="Pilih durasi" selectionMode={'single'} className="max-w-xs" selectedKeys={duration} onSelectionChange={_onDurationChange}>
      <SelectItem key={"today"} value={"today"}>Hari ini</SelectItem>
      <SelectItem key={"yesterday"} value={"yesterday"}>Kemarin</SelectItem>
      <SelectItem key={"thisWeek"} value={"thisWeek"}>Minggu ini</SelectItem>
      <SelectItem key={"lastWeek"} value={"lastWeek"}>Minggu lalu</SelectItem>
      <SelectItem key={"thisMonth"} value={"thisMonth"}>Bulan ini</SelectItem>
      <SelectItem key={"lastMonth"} value={"lastMonth"}>Bulan lalu</SelectItem>
    </Select>
    {!loaded ? <Spinner label={'Memuat data ...'} /> : null}
    {loaded ? <Container gap={20}>
      {renderTripSummaryCard()}
      {renderDriverTopUpSummaryCard()}
    </Container> : null}
  </Container>
};

export default AnalyticDashboardPage;
