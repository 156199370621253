import React, { useEffect, useState } from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";
import Container from '../../components/container';
import NavbarComponent from '../../components/navbar';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import CustomersPage from './customers';
import CustomersPageExport from './customers/list_export';
import CustomersAbsensi from './customers/absensi';
import styles from './styles.module.scss'
import DriverPage from './drivers';
import OrderPage from './orders';
import AreaPage from './areas';
import DetailCustomerPage from './customers/details';
import FormCustomerPage from './customers/form';
import UserService from '../../services/user';
import { Spinner } from '@nextui-org/react';
import CreateAreaPage from './areas/create';
import UpdateAreaPage from './areas/update';
import AreaDetailsPage from './areas/details';
import CreateAreaPricePage from './areas/prices/create';
import UpdateAreaPricePage from './areas/prices/update';
import AreaPriceDetailsPage from './areas/prices/details';
import DriverDetailsPage from './drivers/details';
import VerificationDriverPage from './drivers/vertification';
import VehicleDetails from './drivers/vehicle/details';
import VehicleVertificationPage from './drivers/vehicle/vertification';
import CreateOrderPage from './orders/create';
import AnalyticDashboardPage from './analytics/dashboard';
import DriverRequestTopUpPage from './drivers/topup/request';
import DriverTopUpDetailsPage from './drivers/topup/details';
import ZonePage from './zones';
import CreateZonePricePage from './zones/prices/create';
import UpdateZonePricePage from './zones/prices/update';
import ZonePriceDetailsPage from './zones/prices/details';
import UpdateZonePage from './zones/update';
import ZoneDetailsPage from './zones/details';
import CreateZonePage from './zones/create';
import DriverWithdrawCreditPage from './drivers/withdraw';
import AdminsPage from './admins';
import FormAdminPage from './admins/form';
import WalletPage from './wallets';
import WalletPageSales from './wallets/index_sales';

type PortalPageProps = {
  children?: React.ReactNode;
};

const PortalPage: React.FC<PortalPageProps> = (props) => {
  const [isNavbarHidden, setIsNavbarHidden] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const user = await UserService.me();
      setLoaded(true);
    } catch (e) {
      window.location.href = "/login";
    }
  }

  return <>
    {!loaded ? <Container style={{ height: '100vh' }} alignItems='center' justifyContent='center'><Spinner /></Container> : null}
    {loaded ? <Container direction={'column'} className={styles.container}>
      <NavbarComponent className={`${styles.navbar} ${isNavbarHidden ? styles.hidden : ''}`} />
      <Container className={styles.content}>
        <Routes>
          {/* analytic pages */}
          <Route path='/' element={<AnalyticDashboardPage />} />
          <Route path='/analytics' element={<AnalyticDashboardPage />} />

          {/* customer pages */}
          <Route path='/customers' element={<CustomersPage />} />
          <Route path='/customers/list' element={<CustomersPage />} />
          <Route path='/customers/absensi' element={<CustomersAbsensi />} />
          <Route path='/customers/list_export' element={<CustomersPageExport />} />
          <Route path='/customers/create' element={<FormCustomerPage />} />
          <Route path='/customers/update/:id' element={<FormCustomerPage />} />
          <Route path='/customers/details/:id' element={<DetailCustomerPage />} />

          {/* area pages */}
          <Route path='/areas' element={<AreaPage />} />
          <Route path='/areas/create' element={<CreateAreaPage />} />
          <Route path='/areas/:areaId' element={<AreaDetailsPage />} />
          <Route path='/areas/:areaId/details' element={<AreaDetailsPage />} />
          <Route path='/areas/:areaId/update' element={<UpdateAreaPage />} />

          <Route path='/areas/:areaId/prices/create' element={<CreateAreaPricePage />} />
          <Route path='/areas/:areaId/prices/:priceId/update' element={<UpdateAreaPricePage />} />
          <Route path='/areas/:areaId/prices/:priceId/details' element={<AreaPriceDetailsPage />} />

          {/* zone pages */}
          <Route path='/zones' element={<ZonePage />} />
          <Route path='/zones/create' element={<CreateZonePage />} />
          <Route path='/zones/:zoneId' element={<ZoneDetailsPage />} />
          <Route path='/zones/:zoneId/details' element={<ZoneDetailsPage />} />
          <Route path='/zones/:zoneId/update' element={<UpdateZonePage />} />

          <Route path='/zones/:zoneId/prices/create' element={<CreateZonePricePage />} />
          <Route path='/zones/:zoneId/prices/:priceId/update' element={<UpdateZonePricePage />} />
          <Route path='/zones/:zoneId/prices/:priceId/details' element={<ZonePriceDetailsPage />} />

          {/* driver pages */}
          <Route path='/drivers' element={<DriverPage />} />
          <Route path='/drivers/:driverId' element={<DriverDetailsPage />} />
          <Route path='/drivers/:driverId/details' element={<DriverDetailsPage />} />
          <Route path='/drivers/:driverId/vehicles/:vehicleId' element={<VehicleDetails />} />
          <Route path='/drivers/:driverId/vehicles/:vehicleId/vertification' element={<VehicleVertificationPage />} />
          <Route path='/drivers/:driverId/vertification' element={<VerificationDriverPage />} />
          <Route path='/drivers/:driverId/withdraw' element={<DriverWithdrawCreditPage />} />
          <Route path='/drivers/:driverId/topup/request' element={<DriverRequestTopUpPage />} />
          <Route path='/drivers/topup/:referenceId/details' element={<DriverTopUpDetailsPage />} />

          {/* order pages */}
          <Route path='/orders' element={<OrderPage />} />
          <Route path='/orders/create' element={<CreateOrderPage />} />
          <Route path='/orders/:orderId' element={<OrderPage />} />

          {/* admins */}
          <Route path='/admins' element={<AdminsPage />} />
          <Route path='/admins/create' element={<FormAdminPage />} />
          <Route path='/admins/:adminId/update' element={<FormAdminPage />} />
          <Route path='/admins/:adminId/update_password' element={<FormAdminPage />} />

          {/* wallets */}
          <Route path='/drivers/wallet_histories' element={<WalletPage />} />
          <Route path='/customers/wallet_histories_sales' element={<WalletPageSales />} />
        </Routes>
      </Container>
    </Container> : null}
  </>;
};

export default PortalPage;