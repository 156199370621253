import React from 'react';
import styles from "./styles.module.scss";
import Container from '../../../components/container';
import { Button, Card, CardBody, Input } from '@nextui-org/react';
import AuthService from '../../../services/auth';

type LoginPageProps = {
  children?: React.ReactNode;
};

type LoginData = {
  username: string;
  password: string;
}

const LoginPage: React.FC<LoginPageProps> = (props) => {
  const [data, setData] = React.useState<LoginData>({
    username: "",
    password: ""
  });
  const [errorData, setErrorData] = React.useState<any>({});
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const _onSubmit = async () => {
    setSubmitting(true);
    try {
      await AuthService.login(data.username, data.password);
      window.location.href = "/";
    } catch (e) {
      setSubmitting(false);
    }
  }

  return (
    <Container direction={'row'} alignItems='center' justifyContent='center' className={styles.container}>
      <Card className='w-full'>
        <CardBody className='gap-5'>
          <Input
            isRequired={true}
            isReadOnly={submitting}
            label="Username"
            placeholder=" "
            labelPlacement="outside"
            value={data.username}
            onValueChange={(value) => {
              data.username = value || "";
              setData({ ...data });
            }}
          />
          <Input
            isRequired={true}
            isReadOnly={submitting}
            type="password"
            label="Password"
            placeholder=" "
            labelPlacement="outside"
            value={data.password}
            onValueChange={(value) => {
              data.password = value || "";
              setData({ ...data });
            }}
          />
          <Button size='sm' color='primary' isLoading={submitting} onClick={_onSubmit}>Masuk</Button>
        </CardBody>
      </Card>
    </Container>
  )
};

export default LoginPage;