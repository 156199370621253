import React from 'react';
import AreaForm from '../../../../components/areas/form';
import Container from '../../../../components/container';
import Text from '../../../../components/text';
import styles from './../styles.module.scss';
import AreaPriceForm from '../../../../components/areas/prices/form';
import { useParams } from 'react-router-dom';

type UpdateAreaPricePageProps = {
    children?: React.ReactNode;
};

const UpdateAreaPricePage: React.FC<UpdateAreaPricePageProps> = (props) => {
    const { areaId, priceId } = useParams();

    return <Container className={styles.container} gap={20}>
        <Text size={'xl'} weight='bold'>Buat Harga</Text>
        {areaId ? <AreaPriceForm areaId={Number(areaId)} priceId={Number(priceId)} /> : null}
    </Container>;
};

export default UpdateAreaPricePage;