import GlobalConfig from './../../config';
import Cookies from 'js-cookie';

interface IRequestServiceOptions {
    disableToken?: boolean;
    disableToastErrorMessage?: boolean;
}

interface ResponseType<T> {
    message: string;
    result: T;
    pagination: T;
    totalItems: T;
    totalPages: number;
}

const Request = {
    get: async <T>(
        api: string,
        options?: IRequestServiceOptions,
    ): Promise<ResponseType<T>> => {
        try {
            const token = Cookies.get('token');
            const response = await fetch(GlobalConfig.apiHostname + api, {
                method: 'GET',
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    authorization:
                        options?.disableToken || !token ? '' : 'Bearer ' + token,
                },
            });

            if (response.ok) {
                const result = await response.json();
                return result;
            } else {
                const error = await response.text();
                throw new Error(error);
            }
        } catch (e) {
            throw e;
        }
    },
    delete: async <T>(
        api: string,
        options?: IRequestServiceOptions,
    ): Promise<ResponseType<T>> => {
        try {
            const token = Cookies.get('token');
            const response = await fetch(GlobalConfig.apiHostname + api, {
                method: 'DELETE',
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    authorization:
                        options?.disableToken || !token ? '' : 'Bearer ' + token,
                },
            });

            if (response.ok) {
                const result = await response.json();
                return result;
            } else {
                const error = await response.text();
                throw new Error(error);
            }
        } catch (e) {
            throw e;
        }
    },
    post: async <T>(
        api: string,
        data: FormData,
        options?: IRequestServiceOptions,
    ): Promise<ResponseType<T>> => {
        try {
            const token = Cookies.get('token');
            const response = await fetch(GlobalConfig.apiHostname + api, {
                method: 'POST',
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    Authorization:
                        options?.disableToken || !token ? '' : 'Bearer ' + token,
                    Accept: 'application/json',
                },
                body: data
            });
            if (response.ok) {
                const result = await response.json();
                return result;
            } else {
                const error = await response.text();
                throw new Error(error);
            }
        } catch (e) {
            throw e;
        }
    },
    put: async <T>(
        api: string,
        data: FormData,
        options?: IRequestServiceOptions,
    ): Promise<ResponseType<T>> => {
        try {
            const token = Cookies.get('token');
            const response = await fetch(
                GlobalConfig.apiHostname + api + '?_method=PUT',
                {
                    method: 'POST',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        Authorization:
                            options?.disableToken || !token ? '' : 'Bearer ' + token,
                        Accept: 'application/json',
                    },
                    body: data,
                },
            );

            if (response.ok) {
                const result = await response.json();
                return result;
            } else {
                const error = await response.text();
                throw new Error(error);
            }
        } catch (e) {
            throw e;
        }
    }
}

export default Request;