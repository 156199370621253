import React, { useEffect, useState } from 'react';
import Container from '../../container';
import { Divider, Spinner } from '@nextui-org/react';
import Text from '../../text';
import Footer from '../../footer';
import GlobalConfig from '../../../config';
import { useNavigate } from 'react-router-dom';
import { IDriverResourceShort } from '../../../props/drivers';
import DriverService from '../../../services/drivers';
import moment from 'moment';
import GeneralFileService from '../../../services/general/file';

type TypeVertificationDriverProps = {
  children?: React.ReactNode;
  driverId: string
};

const VertificationDriver: React.FC<TypeVertificationDriverProps> = (props) => {
  const navigate = useNavigate();

  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [driver, setDriver] = React.useState<IDriverResourceShort | undefined>();
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    getDriver()
    return () => {

    }
  }, [])

  const getDriver = async () => {
    try {
      const _driver = (await DriverService.read(props.driverId)).result;
      setDriver(_driver)
      setLoaded(true)
    } catch (error) {

    }
  }

  const accept = async () => {
    setSubmitting(true)
    try {
      await DriverService.accept(props.driverId)
      navigate(`/drivers/${driver?.id}`)
    } catch (error) {
      setSubmitting(false)

    }
  }

  return (
    <Container gap={20}>
      {!loaded ? <Spinner label='Memuat detail ...' /> : null}
      {loaded && driver ? <>
        <Container direction='row' justifyContent='space-between' alignItems='center'>
          <Container direction='row' alignItems='center' gap={10}>
            <img
              src={GeneralFileService.getFileUrl(driver.profile_picture)}
              alt="profile"
              style={{ height: 40, width: 40, borderRadius: 100 }} />
            <Container>
              <Text size='sm' weight='normal'>Jonathan Halim</Text>
              <Text size='xs'>Laki-laki</Text>
            </Container>
          </Container>
        </Container>
        <Container gap={10}>
          <Container>
            <Text size='xs'>Alamat Email</Text>
            <Text size='sm' weight='medium'>{driver.email || '-'}</Text>
          </Container>
          <Container>
            <Text size='xs'>Nomor Telepon</Text>
            <Text size='sm' weight='medium'>{driver.phone_with_code || '-'}</Text>
          </Container>
          <Container>
            <Text size='xs'>Tanggal Bergabung</Text>
            <Text size='sm' weight='medium'>{moment(driver.created_at).format('DD/MM/YYYY')}</Text>
          </Container>
        </Container>
        <Divider />
        <Container gap={10}>
          {driver.address ? <Container>
            <Text size='xs'>Alamat</Text>
            <Text size='sm' weight='medium'>{driver.address || '-'}</Text>
          </Container> : null}
          <Container>
            <Text size='xs'>Tanggal Lahir</Text>
            <Text size='sm' weight='medium'>{moment(driver.date_of_birth).format("DD/MM/YYYY")}</Text>
          </Container>
        </Container>
        <Divider />
        <Container gap={10}>
          <Container gap={5}>
            <Text size='xs'>Foto KTP & SIM</Text>
            <img src={GeneralFileService.getFileUrl(driver.id_proof)} style={{maxWidth: '250px'}} alt="stnk" />
          </Container>
        </Container>
        <Footer buttons={[
          {
            key: 'create',
            color: 'success',
            width: '50%',
            children: "Terima",
            onClick: () => { accept() },
            disabled: submitting,
          },
          {
            key: 'cancel',
            width: '50%',
            color: 'danger',
            children: "Tolak",
            onClick: () => {
              navigate(`/drivers/${driver.id}`)
            },
            disabled: submitting,
          }
        ]} />
      </> : null}
    </Container>
  )
};

export default VertificationDriver;