import React, { useEffect } from 'react';
import Text from '../../text';
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Spinner, Modal, ModalContent, ModalHeader, ModalBody } from "@nextui-org/react";
import { IStationAreaResourceShortProps } from '../../../props/stations/area';
import { useNavigate } from 'react-router-dom';
import { IStationQueueResourceShortProps } from '../../../props/stations/queue';
import StationQueueService from '../../../services/station/queue';
import moment from 'moment';


type AreaQueueListProps = {
	children?: React.ReactNode;
	area: IStationAreaResourceShortProps;
	onClose(): void;
};

const AreaQueueList: React.FC<AreaQueueListProps> = (props) => {
	const navigate = useNavigate();
	const { area } = props;

	const [queues, setQueues] = React.useState<IStationQueueResourceShortProps[]>([]);
	const [loaded, setLoaded] = React.useState<boolean>(false);

	useEffect(() => {
		init();
	}, []);

	const init = async () => {
		const _queues = await StationQueueService.retrieve(area.id);
		setQueues(_queues.result);
		setLoaded(true);
	}

	return (
		<Modal size='sm' isOpen={true} onOpenChange={(isOpen) => { if (!isOpen) { props.onClose() } }}>
			<ModalContent>
				<ModalHeader className="flex flex-col gap-1">Antrian di {area.name}</ModalHeader>
				<ModalBody>
					{!loaded ? <Spinner /> : null}
					{loaded && area ? <>
						<Table aria-label="Daftar area" isStriped>
							<TableHeader>
								<TableColumn width={30}>No.</TableColumn>
								<TableColumn>Driver</TableColumn>
								<TableColumn width={100}>Jam Absen</TableColumn>
							</TableHeader>
							<TableBody isLoading={!loaded} loadingContent={<Spinner />} emptyContent={<Text>Belum ada antrian</Text>}>
								{queues.map((queue, idx) => {
									return <TableRow key={area.id}>
										<TableCell>
											<Text size='base' weight='medium'>{(idx || 0) + 1}</Text>
										</TableCell>
										<TableCell>
											<Text size='base' weight='medium'>{queue.driver.first_name}{queue.driver.last_name ? " " + queue.driver.last_name : ""}</Text>
										</TableCell>
										<TableCell>
											<Text size='base' weight='medium'>{moment(queue.checkin_time).format("hh:mm A")}</Text>
										</TableCell>
									</TableRow>;
								})}
							</TableBody>
						</Table>
					</> : null}
				</ModalBody>
			</ModalContent>
		</Modal>
	)
};

export default AreaQueueList;