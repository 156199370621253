import React from 'react';
import Container from '../../../../components/container';
import Text from '../../../../components/text';
import DriverVehicleDetails from '../../../../components/drivers/vehicle/details';
import { useNavigate, useParams } from 'react-router-dom';
import DropdownButton, { DropdownButtonSectionProps } from '../../../../components/buttons/dropdown';
import styles from '../styles.module.scss'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';

type TypeVehicleDetailsProps = {
  children?: React.ReactNode;
};

const VehicleDetailsPage: React.FC<TypeVehicleDetailsProps> = (props) => {
  const { driverId, vehicleId } = useParams();

  return (
    <Container className={styles.container}>

      <DriverVehicleDetails driverId={driverId as string} vehicleId={vehicleId as string} />
    </Container>
  )
};

export default VehicleDetailsPage;