import { IStationQueueResourceShortProps } from '../../props/stations/queue';
import Request from './../general/request';

const StationQueueService = {
    retrieve: async (areaId: number, qs?: string) => {
        try {
            const response = await Request.get<IStationQueueResourceShortProps[]>(`/api/admin/stations/areas/${areaId}/queues?${qs || ''}`);
            return response;
        } catch (error) {
            throw error
        }
    }
}

export default StationQueueService;