import { IVehicleCategoryResourceShortProps } from '../../props/vehicles/type';
import Request from './../general/request';

const VehicleCategoryService = {
    retrieve: async () => {
        try {
            const response = await Request.get<IVehicleCategoryResourceShortProps[]>('/api/vehicles/categories');
            return response;
        } catch (error) {
            throw error
        }
    }
}

export default VehicleCategoryService;