import React from 'react';
import Container from '../container';
import { Input } from '@nextui-org/react';

type TypeCustomerFormProps = {
  children?: React.ReactNode;
};

const CustomerForm: React.FC<TypeCustomerFormProps> = (props) => {
  return (
    <Container gap={15}>
      <Input
        size='sm'
        label="Nama Lengkap"
        placeholder="nama"
        labelPlacement="outside"
      />
      <Input
        size='sm'
        type="email"
        label="Email"
        placeholder="you@example.com"
        labelPlacement="outside"
      />
      <Input
        size='sm'
        label="Nomor Telepon"
        placeholder="+6281337570000"
        labelPlacement="outside"
      />
    </Container>
  );
};

export default CustomerForm;