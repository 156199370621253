import React from 'react';
import Text from '../text';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Input, Button, ButtonProps } from "@nextui-org/react";
import DropdownButton, { DropdownButtonSectionProps } from '../buttons/dropdown';
import Container from '../container';
import styles from './styles.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';


type FooterProps = {
    children?: React.ReactNode;
    buttons?: FooterButtonProps[];
    currentPage: number;
    totalPages: number;
    handlePrevPage: () => void;
    handleNextPage: () => void;
  };
  
  export interface FooterButtonProps extends ButtonProps {
    width?: string;
  }
  
  const Footer: React.FC<FooterProps> = (props) => {
    const textCenterStyle: React.CSSProperties = {
        textAlign: 'center',
        alignItems: 'center',
        alignContent: 'center',
        padding: '5px'
    };
    
    return (
      <Container className={styles.container} style={textCenterStyle}>
        {props.children ? (
          props.children
        ) : (
          <Container direction="row" gap={0}>
            {props.buttons ? (
              props.buttons.map((button, index) => (
                <>
                  {/* {index === 1 && (
                    <Text style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      Page {props.currentPage} of {props.totalPages}
                    </Text>
                  )} */}
                  <Button {...button} style={{ width: button.width, marginLeft:5}}>
                    {button.children}
                  </Button>
                </>
              ))
            ) : null}
          </Container>
        )}
      </Container>
    );
  };
  

export default Footer;