import React, { useEffect } from 'react';
import Text from '../../text';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { Spinner, Divider, Chip } from "@nextui-org/react";
import DropdownButton, { DropdownButtonSectionProps } from '../../buttons/dropdown';
import Container from '../../container';
import styles from './styles.module.scss';
import { Circle, GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import StationAreaService from '../../../services/station/area';
import { useNavigate } from 'react-router-dom';
import GlobalConfig from '../../../config';
import { IStationAreaResourceShortProps } from '../../../props/stations/area';
import AreaPriceList from '../prices/list';
import InactivateArea from '../modals/inactivate';
import ActivateArea from '../modals/activate';
import AreaQueueList from '../modals/queues';


type AreaDetailsProps = {
    areaId: number;
};

const AreaDetails: React.FC<AreaDetailsProps> = (props) => {
    const navigate = useNavigate();

    const mapStatus = useJsApiLoader(GlobalConfig.googleMapConfig as any);

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [area, setArea] = React.useState<IStationAreaResourceShortProps | undefined>();
    const [activeModal, setActiveModal] = React.useState<string>("");

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _area = await StationAreaService.read(props.areaId);
        setArea(_area.result);
        setLoaded(true);
    }

    const getActions = (): DropdownButtonSectionProps[] => {
        const actions: DropdownButtonSectionProps[] = [
            {
                key: 'details',
                showDivider: true,
                items: [
                    { key: 'update', text: 'Ubah detail', onPress: (area: IStationAreaResourceShortProps) => { navigate(`/areas/${area.id}/update`); } }
                ]
            },
            {
                key: 'queues',
                showDivider: true,
                items: [
                    { key: 'queue', text: 'Lihat antrian', onPress: () => { setActiveModal('queues') } }
                ]
            }
        ];

        if (area && area.is_active) {
            actions.push({
                key: 'deletion',
                items: [
                    { key: 'inactivate', text: 'Non-aktifkan area', variant: 'danger', onPress: () => { setActiveModal('inactivate') } }
                ]
            });
        } else {
            actions.push({
                key: 'activation',
                items: [
                    { key: 'activate', text: 'Aktifkan area', variant: 'success', onPress: () => { setActiveModal('activate') } }
                ]
            });
        }

        return actions;
    }

    const _onCloseModal = (refresh?: boolean) => {
        setActiveModal("");

        if (refresh) {
            setLoaded(false);
            init();
        }
    }

    return <Container className={styles.container} gap={20} style={{
            backgroundColor: 'white',
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 20,
            // alignItems: 'center',
            // justifyContent: 'center',
            // height: '80vh',
        }}>
        {!loaded ? <Spinner label='Memuat detail ...' /> : null}
        {loaded && area ? <>
            <Container direction='row' justifyContent='space-between' alignItems='center'>
                <Text size={'xl'} weight='bold'>{area.name}</Text>
                <DropdownButton data={area} sections={getActions()} backdrop='blur' icon={faChevronDown} />
            </Container>
            <Container gap={20}>
                <Container gap={5}>
                    <Text size={'small'} weight={'medium'}>Status</Text>
                    {!area.is_active ? <Chip color='danger'>Tidak Aktif</Chip> : null}
                    {area.is_active ? <Chip color='success'>Aktif</Chip> : null}
                </Container>
                <Container gap={5}>
                    <Text size={'small'} weight={'medium'}>Titik Tengah Area</Text>
                    {mapStatus.isLoaded ? <GoogleMap mapContainerStyle={{ height: 200, maxWidth: 720, borderRadius: 10, border: '1px solid #c8c6c4' }}
                        zoom={15}
                        center={{ lat: Number(area.latitude), lng: Number(area.longitude) }} >
                        <MarkerF position={{ lat: Number(area.latitude), lng: Number(area.longitude) }} />
                        <Circle visible={true}
                            center={{ lat: Number(area.latitude), lng: Number(area.longitude) }}
                            radius={Number(area.radius) * 1000}
                            options={{fillColor: '#006cbe5e', strokeColor: '#006cbe'}} />
                    </GoogleMap> : null}
                </Container>
                <Container>
                    <Text size={'small'} weight={'medium'}>Latitude, Longitude</Text>
                    <Text>{area.latitude}, {area.longitude}</Text>
                </Container>
                <Container>
                    <Text size={'small'} weight={'medium'}>Radius</Text>
                    <Text>{area.radius} km</Text>
                </Container>
                <Divider />
            </Container>
            <AreaPriceList areaId={area.id} />
        </> : null}
        {activeModal === 'inactivate' && area ? <InactivateArea area={area} onClose={_onCloseModal} /> : null}
        {activeModal === 'activate' && area ? <ActivateArea area={area} onClose={_onCloseModal} /> : null}
		{activeModal === 'queues' && area ? <AreaQueueList area={area} onClose={_onCloseModal} /> : null}
    </Container>
};

export default AreaDetails;