import React from 'react';
import Container from '../../../../components/container';
import Text from '../../../../components/text';
import DriverVehicleDetails from '../../../../components/drivers/vehicle/details';
import { useNavigate, useParams } from 'react-router-dom';
import DropdownButton, { DropdownButtonSectionProps } from '../../../../components/buttons/dropdown';
import styles from '../styles.module.scss'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import DriverRequestTopUp from '../../../../components/drivers/topup/create';

type DriverRequestTopUpPageProps = {
  children?: React.ReactNode;
};

const DriverRequestTopUpPage: React.FC<DriverRequestTopUpPageProps> = (props) => {
  const { driverId } = useParams();

  return (
    <Container className={styles.container}>
      {driverId ? <DriverRequestTopUp driverId={driverId} /> : null}
    </Container>
  )
};

export default DriverRequestTopUpPage;