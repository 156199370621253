import React from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import Container from '../../../components/container';
import Text from '../../../components/text';
import styles from './styles.module.scss';
import ZoneDetails from '../../../components/zones/details';

type ZoneDetailsPageProps = {
    children?: React.ReactNode;
};

const ZoneDetailsPage: React.FC<ZoneDetailsPageProps> = (props) => {
    const { zoneId } = useParams();

    return <Container className={styles.container} gap={20}>
      <ZoneDetails zoneId={Number(zoneId)} />
    </Container>;
};

export default ZoneDetailsPage;