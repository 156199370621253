import React, { useEffect } from 'react';
import Text from '../../../text';
import { Input, Spinner, Divider } from "@nextui-org/react";
import Container from '../../../container';
import styles from './styles.module.scss';
import Footer from '../../../footer';
import { useJsApiLoader } from '@react-google-maps/api';
import StationAreaService from '../../../../services/station/area';
import { useNavigate } from 'react-router-dom';
import GlobalConfig from '../../../../config';
import { IStationAreaResourceShortProps } from '../../../../props/stations/area';
import StationAreaPriceService from '../../../../services/station/price';


type AreaPriceFormProps = {
    areaId: number;
    priceId?: number;
};

type AreaDataProps = {
    minKm: string;
    maxKm: string;
    surcharge: string;
    pricePerKm: string;
    minPrice: string;
    basePrice: string;
    parkingFee: string;
    dailyParkingFee: string;
    percentageFee: string;
}

const AreaPriceForm: React.FC<AreaPriceFormProps> = (props) => {
    const navigate = useNavigate();

    const mapStatus = useJsApiLoader(GlobalConfig.googleMapConfig as any);

    const mode: 'create' | 'update' = props.priceId ? 'update' : 'create';
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [area, setArea] = React.useState<IStationAreaResourceShortProps | undefined>();
    const [data, setData] = React.useState<AreaDataProps>({
        minKm: "",
        maxKm: "",
        surcharge: "",
        pricePerKm: "",
        minPrice: "",
        parkingFee: "",
        dailyParkingFee: "",
        percentageFee: "",
        basePrice: ""
    });
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _area = await StationAreaService.read(props.areaId);
        setArea(_area.result);

        if (props.priceId) {
            const _price = await StationAreaPriceService.read(props.areaId, props.priceId);
            setData({
                minKm: Number(_price.result.min_km) + "",
                maxKm: Number(_price.result.max_km) + "",
                surcharge: Number(_price.result.surcharge) + "",
                pricePerKm: Number(_price.result.price_per_km) + "",
                minPrice: Number(_price.result.min_price) + "",
                basePrice: Number(_price.result.base_price) + "",
                parkingFee: Number(_price.result.parking_fee) + "",
                dailyParkingFee: Number(_price.result.daily_parking_fee) + "",
                percentageFee: Number(_price.result.percentage_fee) + ""
            })
        }

        setLoaded(true);
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const fd = new FormData();
            fd.append('min_km', data.minKm.trim() === "" ? "0" : data.minKm);
            fd.append('max_km', data.maxKm.trim() === "" ? "0" : data.maxKm);
            fd.append('surcharge', data.surcharge.trim() === "" ? "0" : data.surcharge);
            fd.append('min_price', data.minPrice.trim() === "" ? "0" : data.minPrice);
            fd.append('price_per_km', data.pricePerKm.trim() === "" ? "0" : data.pricePerKm);
            fd.append('parking_fee', data.parkingFee.trim() === "" ? "0" : data.parkingFee);
            fd.append('daily_parking_fee', data.dailyParkingFee.trim() === "" ? "0" : data.dailyParkingFee);
            fd.append('percentage_fee', data.percentageFee.trim() === "" ? "0" : data.percentageFee);
            fd.append('base_price', data.basePrice.trim() === "" ? "0" : data.basePrice);

            if (mode === 'update' && props.priceId) {
                await StationAreaPriceService.update(props.areaId, props.priceId, fd);
            } else {
                await StationAreaPriceService.create(props.areaId, fd);
            }
            navigate('/areas/' + props.areaId + '/details');
        } catch (e) {
            setSubmitting(false);
        }
    }

    return <Container className={styles.container} style={{
            backgroundColor: 'white',
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 20,
            // alignItems: 'center',
            // justifyContent: 'center',
            // height: '80vh',
        }}>
        {!loaded ? <Spinner label='Memuat form ...' /> : null}
        {loaded ? <>
            <Container gap={15}>
                <Input label="Minimum km"
                    placeholder="Tarif berlaku mulai jarak ..."
                    labelPlacement="outside"
                    isRequired={true}
                    value={data.minKm}
                    isReadOnly={submitting}
                    endContent={<Text>km</Text>}
                    onValueChange={(value) => {
                        data.minKm = value || "";
                        setData({ ...data });
                    }} />
                <Input label="Maksimum km"
                    placeholder="Tarif berlaku sampai jarak ..."
                    labelPlacement="outside"
                    isRequired={true}
                    value={data.maxKm}
                    isReadOnly={submitting}
                    endContent={<Text>km</Text>}
                    onValueChange={(value) => {
                        data.maxKm = value || "";
                        setData({ ...data });
                    }} />
                <Divider />
                <Input label="Harga Dasar"
                    placeholder="Harga dasar per trip ..."
                    labelPlacement="outside"
                    isRequired={true}
                    value={data.basePrice}
                    isReadOnly={submitting}
                    startContent={<Text>Rp.</Text>}
                    onValueChange={(value) => {
                        data.basePrice = value || "";
                        setData({ ...data });
                    }} />
                <Input label="Harga Minimum"
                    placeholder="Harga minimum per trip ..."
                    labelPlacement="outside"
                    isRequired={true}
                    value={data.minPrice}
                    isReadOnly={submitting}
                    startContent={<Text>Rp.</Text>}
                    onValueChange={(value) => {
                        data.minPrice = value || "";
                        setData({ ...data });
                    }} />
                <Input label="Harga per km"
                    placeholder="Harga per km ..."
                    labelPlacement="outside"
                    isRequired={true}
                    value={data.pricePerKm}
                    isReadOnly={submitting}
                    startContent={<Text>Rp.</Text>}
                    endContent={<Text style={{ width: 40 }}>/ km</Text>}
                    onValueChange={(value) => {
                        data.pricePerKm = value || "";
                        setData({ ...data });
                    }} />
                <Input label="Surcharge"
                    placeholder="Harga surcharge per trip ..."
                    labelPlacement="outside"
                    isRequired={true}
                    value={data.surcharge}
                    isReadOnly={submitting}
                    startContent={<Text>Rp.</Text>}
                    onValueChange={(value) => {
                        data.surcharge = value || "";
                        setData({ ...data });
                    }} />
                <Input label="Persentase Potongan"
                    placeholder="Potongan dalam persentase ..."
                    labelPlacement="outside"
                    isRequired={true}
                    value={data.percentageFee}
                    isReadOnly={submitting}
                    endContent={<Text>%</Text>}
                    onValueChange={(value) => {
                        data.percentageFee = value || "";
                        setData({ ...data });
                    }} />
                <Divider />
                <Input label="Biaya Parkir"
                    placeholder="Harga parkir per trip ..."
                    labelPlacement="outside"
                    value={data.parkingFee}
                    isReadOnly={submitting}
                    startContent={<Text>Rp.</Text>}
                    onValueChange={(value) => {
                        data.parkingFee = value || "";
                        setData({ ...data });
                    }} />
                <Input label="Biaya Parkir per Hari"
                    placeholder="Harga parkir per hari ..."
                    labelPlacement="outside"
                    value={data.dailyParkingFee}
                    isReadOnly={submitting}
                    startContent={<Text>Rp.</Text>}
                    onValueChange={(value) => {
                        data.dailyParkingFee = value || "";
                        setData({ ...data });
                    }} />
            </Container>
            <Footer buttons={[
                {
                    key: 'create',
                    color: 'primary',
                    width: '70%',
                    children: mode === 'create' ? "Buat" : "Simpan",
                    isLoading: submitting,
                    onPress: _onSubmit
                },
                {
                    key: 'cancel',
                    width: '30%',
                    children: "Batal",
                    isDisabled: submitting,
                    onClick: () => navigate(-1)
                }
            ]} />
        </> : null}
    </Container>
};

export default AreaPriceForm;