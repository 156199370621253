import React, { useEffect } from 'react';
import Text from '../../../text';
import { faChevronDown, faPlus } from '@fortawesome/pro-light-svg-icons';
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Spinner, Button } from "@nextui-org/react";
import DropdownButton, { DropdownButtonSectionProps } from '../../../buttons/dropdown';
import Container from '../../../container';
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberService from '../../../../services/general/number';
import { IZoneResourceShortProps } from '../../../../props/zones/zone';
import { IZonePriceResourceShortProps } from '../../../../props/zones/price';
import ZoneService from '../../../../services/zones/zone';
import ZonePriceService from '../../../../services/zones/price';
import DeleteZonePrice from '../modals/delete';


type ZonePriceListProps = {
	children?: React.ReactNode;
    zoneId: number;
};

const ZonePriceList: React.FC<ZonePriceListProps> = (props) => {
	const navigate = useNavigate();
	
	const [zone, setZone] = React.useState<IZoneResourceShortProps | undefined>();
	const [prices, setPrices] = React.useState<IZonePriceResourceShortProps[]>([]);
	const [loaded, setLoaded] = React.useState<boolean>(false);
	const [activePrice, setActivePrice] = React.useState<IZonePriceResourceShortProps | undefined>();
	const [activeModal, setActiveModal] = React.useState<string>("");

	const actions: DropdownButtonSectionProps[] = [
		{
			key: 'details',
			showDivider: true,
			items: [
				{ key: 'view', text: 'Lihat detail', onPress: (price: IZonePriceResourceShortProps) => {navigate(`/zones/${props.zoneId}/prices/${price.id}/details`);} },
				{ key: 'update', text: 'Ubah detail', onPress: (price: IZonePriceResourceShortProps) => {navigate(`/zones/${props.zoneId}/prices/${price.id}/update`);} }
			]
		},
		{
			key: 'deletion',
			items: [
				{ key: 'queue', text: 'Hapus harga', variant: 'danger', onPress: (price: IZonePriceResourceShortProps) => {
					setActivePrice(price);
					setActiveModal('delete');
				} }
			]
		}
	];

	useEffect(() => {
		init();
	}, []);

	const init = async () => {
		const _zone = (await ZoneService.read(props.zoneId)).result;
		setZone(_zone);

        const _prices = (await ZonePriceService.retrieve(props.zoneId)).result;
		setPrices(_prices);

		setLoaded(true);
	}

	const _onCloseModal = (refresh?: boolean) => {
		setActiveModal("");
		setActivePrice(undefined);

		if (refresh) {
			setLoaded(false);
			init();
		}
	}

	return <Container gap={20}>
		<Container direction='row' justifyContent='space-between' alignItems='center' gap={10} style={{ zIndex: 1 }}>
            <Text>Daftar Harga</Text>
			<NavLink to={`/zones/${props.zoneId}/prices/create`}>
				<Button isIconOnly color='primary' size='sm'>
					<FontAwesomeIcon icon={faPlus} />
				</Button>
			</NavLink>
		</Container>
		<Table aria-label="Daftar zona" isStriped>
			<TableHeader>
				<TableColumn width={40}>No</TableColumn>
				<TableColumn width={100}>Jarak</TableColumn>
				<TableColumn>Surcharge</TableColumn>
				<TableColumn>Harga /km</TableColumn>
				<TableColumn width={40}>Potongan</TableColumn>
				<TableColumn width={40}> </TableColumn>
			</TableHeader>
			<TableBody isLoading={!loaded} loadingContent={<Spinner />} emptyContent={<Text>Belum ada harga untuk zona ini</Text>}>
				{prices.map((price,index) => {
                    let dist: string = "";
                    if (price.max_km > 0) {
                        dist = `${Number(price.min_km)}-${Number(price.max_km)} km`
                    } else if (price.max_km <= 0) {
                        dist = `> ${Number(price.min_km)} km`;
                    }

					return <TableRow key={price.id}>
						<TableCell>
							<Text size='small' weight='medium'>{index+1}</Text>
						</TableCell>
						<TableCell>
							<Text size='small' weight='medium'>{dist}</Text>
						</TableCell>
						<TableCell>
							<Text size='small' weight='medium'>Rp. {NumberService.getNumberWithSeparator(Number(price.surcharge))}</Text>
						</TableCell>
						<TableCell>
							<Text size='small' weight='medium'>Rp. {NumberService.getNumberWithSeparator(Number(price.price_per_km))}</Text>
						</TableCell>
						<TableCell>
							<Text size='small' weight='medium'>{Number(price.percentage_fee)}%</Text>
						</TableCell>
						<TableCell key={'action'}>
							<DropdownButton icon={faChevronDown} sections={actions} backdrop='blur' data={price} />
						</TableCell>
					</TableRow>;
				})}
			</TableBody>
		</Table>
		{activeModal === 'delete' && zone && activePrice ? <DeleteZonePrice zone={zone} price={activePrice} onClose={_onCloseModal} /> : null}
	</Container>;
};

export default ZonePriceList;