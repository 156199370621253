import React, { useEffect, useState } from 'react';
import Container from '../../container';
import DriverService from '../../../services/drivers';
import { Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Image, Button, Modal, ModalBody, ModalHeader, ModalContent, ModalFooter, useDisclosure } from '@nextui-org/react';
import Text from '../../text';
import NumberService from '../../../services/general/number';
import DropdownButton, { DropdownButtonSectionProps } from '../../buttons/dropdown';
import { faArrowRight, faChevronDown, faFilter } from '@fortawesome/pro-light-svg-icons';
import { IDriverWalletHistoryResourceShortProps } from '../../../props/drivers/walletHistory';
import { IDriverResourceShort } from '../../../props/drivers';
import { useNavigate } from 'react-router-dom';
import GlobalConfig from '../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import Filterhistory from '../modals/filter';
import Footer from '../../footerpage';
import * as XLSX from 'xlsx';
import CustomerService from '../../../services/customers';
import { ICustomerWalletHistoryResourceShortProps } from '../../../props/customers/walletHistory';
import { ICustomerResourceShort } from '../../../props/customers';
import FilterhistorySales from '../modals/filter_customer';

type HistoryType = 'withdraw' | 'topup'

type TypeDriverWalletHistoryProps = {
  children?: React.ReactNode;
};

const DriverWalletHistory: React.FC<TypeDriverWalletHistoryProps> = (props) => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>();
  const [histories, setHistories] = React.useState<ICustomerWalletHistoryResourceShortProps[]>([])
  const [startDate, setStartDate] = React.useState<string>((new Date().toLocaleString('en-US', { timeZone: 'Asia/Jakarta' })));
  const [endDate, setEndDate] = React.useState<string>((new Date().toLocaleString('en-US', { timeZone: 'Asia/Jakarta' })));
  const [filterModal, setFilterModal] = useState<boolean>(false)
  const [historyTypes, setHistoryTypes] = useState<string[]>([])
  const [driver, setDriver] = useState<ICustomerResourceShort>()

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSetItemsPerPage = (value:any) => {
    setItemsPerPage(value);
    setCurrentPage(1)
  };

  useEffect(() => {
    getHistories()
    return () => { }
  }, [startDate, endDate, historyTypes, driver, currentPage, itemsPerPage])

  const getHistories = async () => {
    setLoaded(false);

    const startDateTemp = new Date(startDate);
    startDateTemp.setHours(0, 0, 1, 0);

    const endDateTemp = new Date(endDate);
    endDateTemp.setHours(23, 59, 59, 0);

    const qs: string[] = [];
    startDate && qs.push(`start_time=${formatDate(startDateTemp)}`);
    endDate && qs.push(`end_time=${formatDate(endDateTemp)}`);
    if (historyTypes.length !== 0) {
      qs.push(`history_types=${historyTypes}`);
    }
    if (driver) {
      qs.push(`customer_id=${driver.id}`);
    }
     // Include pagination parameters
    qs.push(`page=${currentPage}`);
    qs.push(`per_page=${itemsPerPage}`);

    try {
      setHistories([]);
      const response = await CustomerService.history.list(qs.join("&"))
      setLoaded(true)
      setHistories(response.pagination)
      setTotalPages(response.totalPages)
    } catch (error) {

    }
  }

  const jsonResult = histories.map((item, index) => {
    return {
      No: index+1,
      Nama: item.customer.first_name +' '+item.customer.last_name || '',
      'Tipe Transaksi': item.history_type,
      'Detail Transaksi': item.message,
      'Metode Pembayaran': item.payment_method === 'qrcode' ? 'QRIS' :item.payment_method,
      'Nomor Pembayaran': item.reference_id,
      'Tanggal Transaksi': formatDate(new Date(item.created_at)),
      'Status': item.status === 20 ? 'Pending' :item.status === 21 ? 'Sukses' :item.status === 22 ? 'Gagal' :'Unknown' ,
      'Nominal': "Rp. "+ NumberService.getNumberWithSeparator(item.amount),
      'Saldo Sebelumnya': "Rp. "+ NumberService.getNumberWithSeparator(item.previous_balance == null ? 0 :item.previous_balance),
      'Saldo Sesudah': "Rp. "+ NumberService.getNumberWithSeparator(item.last_balance == null ? 0 :item.last_balance),
    };
  });
  

  const exportToExcel = (jsonResult: any[]) => {
    // Convert data to worksheet
    const ws = XLSX.utils.json_to_sheet(jsonResult);
  
    // Add styling (borders) to all cells
    // const range = XLSX.utils.decode_range(ws['!ref'] || 'A1:A1');
    // for (let R = range.s.r; R <= range.e.r; ++R) {
    //   for (let C = range.s.c; C <= range.e.c; ++C) {
    //     const cellAddress = { r: R, c: C };
    //     const cellRef = XLSX.utils.encode_cell(cellAddress);

    //     // Check if cell exists before applying styling
    //     if (ws[cellRef]) {
    //       ws[cellRef].s = { border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } } };
    //     }
    //   }
    // }
  
    // Auto-width columns
    const colWidth = jsonResult.reduce((acc, data) => {
      return Object.keys(data).reduce((acc, key) => {
        const cellLength = String(data[key]).length;
        return { ...acc, [key]: Math.max(acc[key] || 0, cellLength) };
      }, acc);
    }, {});
  
    const cols = Object.keys(colWidth).map(key => ({ wch: colWidth[key] + 2 })); // Add extra space
  
    ws['!cols'] = cols;
  
    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    // Save the workbook to a file
    XLSX.writeFile(wb, 'Export Wallet History '+startDate.split(',')[0].trim().replace(/\//g, '-') + ' Sampai ' + endDate.split(',')[0].trim().replace(/\//g, '-') +'.xlsx');
  };
  


  return (
    <Container gap={20}>
      {filterModal &&
        <FilterhistorySales
          onClose={function (): void {
            setFilterModal(false)
          }}
          date={{
            startDate, endDate
          }}
          historyTypes={historyTypes}
          driver={driver}
          onSave={function (data): void {
            data.date.startDate && setStartDate(data?.date?.startDate)
            data.date.endDate && setEndDate(data.date.endDate)
            setHistoryTypes(data.historyTypes)
            setDriver(data.driver)
            setFilterModal(false)
          }}
        />
      }
      <Container direction='row' gap={10}>
        <Button onClick={() => setFilterModal(true)} isIconOnly color='default' aria-label="Like">
          <FontAwesomeIcon icon={faFilter} />
        </Button>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <span>Show : </span>
          <select value={itemsPerPage} onChange={(e) => handleSetItemsPerPage(parseInt(e.target.value))}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
            <option value={99999}>All</option>
          </select>
        </div>
      </Container>
      <Table aria-label="Daftar sales" isStriped>
        <TableHeader>
          <TableColumn>No</TableColumn>
          <TableColumn>Nama</TableColumn>
          <TableColumn>Tipe Transaksi</TableColumn>
          <TableColumn>Metode Pembayaran</TableColumn>
          <TableColumn>Nomor Pembayaran</TableColumn>
          <TableColumn>Detail Transaksi</TableColumn>
          <TableColumn>Tanggal Transaksi</TableColumn>
          <TableColumn>Status</TableColumn>
          <TableColumn>Nominal</TableColumn>
          <TableColumn>Saldo Sebelumnya</TableColumn>
          <TableColumn>Saldo Sesudah</TableColumn>
        </TableHeader>
        <TableBody isLoading={!loaded} loadingContent={<Spinner />} emptyContent={<Text>Belum ada data</Text>}>
          {histories.map((history, index) => {
            const calculatedIndex = (currentPage - 1) * itemsPerPage + index + 1;
            return <TableRow key={history.customer.id}>
              <TableCell aria-label='no'>
                <Text size='base' weight='medium'>{calculatedIndex}</Text>
              </TableCell>
              <TableCell aria-label='first_name'>
                <Container direction='row' alignItems='center' gap={5}>
                  {/* <Image
                    width={50}
                    height={50}
                    alt="profile_picture"
                    src={GlobalConfig.assetHostname + '/storage/' + history.driver.profile_picture}
                  /> */}
                  <Text size='base' weight='medium'>{history.customer.first_name} {history.customer.last_name || ""}</Text>
                </Container>
              </TableCell>
              <TableCell aria-label='status'>
                <Text size='base' weight='medium'>{history.history_type}</Text>
              </TableCell>
              <TableCell aria-label='status'>
                <Text size='base' weight='medium'>{history.payment_method === 'qrcode' ? 'QRIS' :history.payment_method}</Text>
              </TableCell>
              <TableCell aria-label='status'>
                <Text size='base' weight='medium'>{history.reference_id}</Text>
              </TableCell>
              <TableCell aria-label='status'>
                <Text size='base' weight='medium'>{history.message}</Text>
              </TableCell>
              <TableCell key={'action'} aria-label='actions'>
                <Text size='base' weight='medium'>{formatDate(new Date(history.created_at))}</Text>
              </TableCell>
              <TableCell key={'action'} aria-label='actions'>
                {history.status === 20 ? <Text size='base' weight='medium'>Pending</Text> : null}
                {history.status === 21 ? <Text size='base' weight='medium' style={{color: '#007200'}}>Sukses</Text> : null}
                {history.status === 22 ? <Text size='base' weight='medium' style={{color: '#B02E2C'}}>Gagal</Text> : null}
              </TableCell>
              <TableCell aria-label='status'>
                <Text size='base' weight='medium' style={{ color: history.history_type=='deduct' ? '#B02E2C' : '#007200' }}>
                  Rp. {NumberService.getNumberWithSeparator(history.amount)}
                </Text>

              </TableCell>
              
              <TableCell aria-label='status'>
                <Text size='base' weight='medium' style={{ color: history.amount < 0 ? '#B02E2C' : '#007200' }}>
                  Rp. {NumberService.getNumberWithSeparator(history.previous_balance == null ? 0 :history.previous_balance)}
                </Text>
              </TableCell>
              <TableCell aria-label='status'>
                <Text size='base' weight='medium' style={{ color: history.amount < 0 ? '#B02E2C' : '#007200' }}>
                  Rp. {NumberService.getNumberWithSeparator(history.last_balance == null ? 0 :history.last_balance)}
                </Text>
              </TableCell>
            </TableRow>
          })}
        </TableBody>
      </Table>
      <Container direction="row" justifyContent="flex-end">
         <Footer
            currentPage={currentPage}
            totalPages={totalPages}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            buttons={[
              {
                key: 'back',
                color: 'primary',
                width: '30%',
                children: 'Previous',
                isDisabled: currentPage === 1,
                onClick: handlePrevPage,
                isLoading: !loaded
              },
              {
                key: 'number',
                width: '30%',
                children: ' Page '+currentPage+' of ' + totalPages,
                isDisabled: currentPage === totalPages,
              },
              {
                key: 'next',
                color: 'success',
                width: '30%',
                children: 'Next',
                isDisabled: currentPage === totalPages,
                onClick: handleNextPage,
                isLoading: !loaded
              },
              {
                key: 'download',
                color: 'danger',
                width: '30%',
                children: 'Excel',
                onClick: () => exportToExcel(jsonResult),
              },
            ]}
          />;
      </Container>
    </Container>
  )
};

export default DriverWalletHistory;